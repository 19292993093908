import { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import FleetLogo from "../../assets/images/fleet_logo.png";
import MapIcon from "../../assets/images/map.png";
import Safety from "../../assets/images/safety.png";
import Compliance from "../../assets/images/compliance.png";
import Dispatch from "../../assets/images/dispatch.png";
import FuelEnergy from "../../assets/images/fuel_energy.png";
import Documents from "../../assets/images/documents.png";
import Reports from "../../assets/images/reports.png";
import Messages from "../../assets/images/messages.png";
import Maintenance from "../../assets/images/maintenance.png";
import HelpFeedback from "../../assets/images/help_feedback.png";
import Alerts from "../../assets/images/alerts.png";
import Settings from "../../assets/images/settings.png";
import Right from "../../assets/images/right.png";
import Profile from "../../assets/images/profile.png";
import Billing from "../../assets/images/billing.png";
import Orders from "../../assets/images/orders.png";
import ActivateDevices from "../../assets/images/activate_devices.png";
import OrganizationSettings from "../../assets/images/organization_settings.png";
import Logout from "../../assets/images/logout.png";
export default function Sidebar({
  mobile,
  sidebar,
  toggleSidebar,
  openSearchModal,
}) {
  const [active, setActive] = useState(0);
  const setAccordian = (e) => {
    sidebar == false
      ? e === active
        ? setActive(0)
        : setActive(e)
      : setActive(0);
  };
  return (
    <>
      <div
        className={`sidebar-overlay fixed left-0 right-0 bottom-0 top-[50px] bg-[#000000]/20 z-0 md:hidden ${
          !mobile && "hidden"
        }`}
      ></div>
      <div
        className={`${sidebar && "!w-[70px]"} ${
          mobile &&
          " left-0 md:h-screen h-[calc(100vh-50px)] !block !top-[50px]"
        } fixed w-[250px] bg-inverted border-r border-[#e7eaee] transition-all duration-500 md:block hidden md:left-0 -left-[250px] top-0 bottom-0 z-[1] shrink-0`}
      >
        <div className="md:pt-3 flex flex-col h-full">
          <div className="md:flex hidden items-center px-3 overflow-hidden min-h-[45px]">
            <div className="flex items-center">
              <img
                src={FleetLogo}
                alt="Fleet Logo"
                className="w-[30px] opacity-80"
              />
              <p
                className={`text-[18px] fill-[#8390A0] font-semibold ml-2 ${
                  sidebar && "hidden"
                }`}
              >
                Fleet
              </p>
            </div>
            <div
              className={`flex items-center ml-auto gap-2 ${
                sidebar && "hidden"
              }`}
            >
              <div
                data-title="Messages"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Messages"
                className="cursor-pointer"
              >
                <img
                  src={Messages}
                  alt="Messages"
                  className="w-[24px] h-[24px]  opacity-50"
                />
              </div>
              <div
                data-title="Alerts"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Alerts"
                className="cursor-pointer"
              >
                <img
                  src={Alerts}
                  alt="Alerts"
                  className="w-[24px] h-[24px]  opacity-50"
                />
              </div>
            </div>
          </div>
          <div className="pt-4 grow px-3">
            <div className={`${sidebar && "[&_span]:hidden"}`}>
              <div className={`${active !== 2 && "group"} relative`}>
                <NavLink
                  to="/overview"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "bg-[#e7eaee]" : ""
                    } flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 overflow-hidden `
                  }
                >
                  <button
                    className="flex items-center text-[16px] grow"
                    type="button"
                    onClick={() => setAccordian(2)}
                  >
                    <img
                      src={MapIcon}
                      alt="Overview"
                      className="w-[18px] mr-4 shrink-0 opacity-50"
                    />

                    <span> Overview</span>
                  </button>
                </NavLink>
                <div
                  className={`${active === 2 ? "h-[160px]" : "h-0 "} ${
                    sidebar && "!h-full"
                  } transition-all duration-300`}
                >
                  <div
                    className={` ${
                      active !== 2 && " hidden"
                    } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                  >
                    <div className="pl-[33px] pt-0 group-hover:!p-4">
                      <ul>
                        <li>
                          <NavLink
                            to="/assets"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Assets
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/drivers"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Drivers
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/coverage-map"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Coverage Map
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/proximity"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Proximity
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={`${active !== 3 && "group"} relative`}>
                <button
                  className="flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 overflow-hidden"
                  type="button"
                  onClick={() => setAccordian(3)}
                >
                  <img
                    src={Safety}
                    alt="Safety"
                    className="w-[18px] mr-4 shrink-0 opacity-50"
                  />

                  <span> Safety</span>
                </button>
                <div
                  className={`${
                    active === 3 ? "h-[160px]" : "h-0 "
                  }  transition-all duration-300`}
                >
                  <div
                    className={` ${
                      active !== 3 && " hidden"
                    } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                  >
                    <div className="pl-[33px] pt-0 group-hover:!p-4">
                      <ul>
                        <li
                          data-title="Safety Inbox"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Safety Inbox
                          </span>
                        </li>
                        <li
                          data-title="Coaching"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Coaching
                          </span>
                        </li>
                        <li
                          data-title="Cameras"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Cameras
                          </span>
                        </li>
                        <li
                          data-title="Video Library"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Video Library
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className={`${active !== 4 && "group"} relative`}>
                <NavLink
                  to="/compliance/historical"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "bg-[#e7eaee]" : ""
                    } flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 overflow-hidden `
                  }
                >
                  <button
                    className="flex items-center text-[16px] grow"
                    type="button"
                    onClick={() => setAccordian(4)}
                  >
                    <img
                      src={Compliance}
                      alt="Compliance"
                      className="w-[18px] mr-4 shrink-0 opacity-50"
                    />

                    <span> Compliance</span>
                  </button>
                </NavLink>
                <div
                  className={`${
                    active === 4 ? "h-[160px]" : "h-0 "
                  }  transition-all duration-300`}
                >
                  <div
                    className={` ${
                      active !== 4 && " hidden"
                    } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                  >
                    <div className="pl-[33px] pt-0 group-hover:!p-4">
                      <ul>
                        <li>
                          <NavLink
                            to="/infringement"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Infringement
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/tachnograph-downloads/driver"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Tachograph Downloads
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/tachnograph-explorer"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Tachograph Explorer
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/unassigned-driving"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Unassigned Driving
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${active !== 5 && "group"} relative`}>
                <NavLink
                  to="/maintenance-status"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "bg-[#e7eaee]" : ""
                    } flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 overflow-hidden `
                  }
                >
                  <button
                    className="flex items-center text-[16px] grow"
                    type="button"
                    onClick={() => setAccordian(5)}
                  >
                    <img
                      src={Maintenance}
                      alt="Maintenance"
                      className="w-[18px] mr-4 shrink-0 opacity-50"
                    />
                    <span> Maintenance</span>
                  </button>
                </NavLink>
                <div
                  className={`${
                    active === 5 ? "h-[240px]" : "h-0 "
                  }  transition-all duration-300`}
                >
                  <div
                    className={` ${
                      active !== 5 && " hidden"
                    } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                  >
                    <div className="pl-[33px] pt-0 group-hover:!p-4">
                      <ul>
                        <li>
                          <NavLink
                            to="/service-log"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Service Logs
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/walkaround-inspection-reports/defects"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Walkarounds
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/walkaround/templates"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Templates
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/schedules-maintenance"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Schedule
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/upcoming-maintenance"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Upcoming
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/tire-conditions"
                            className={({ isActive, isPending }) =>
                              `${
                                isPending
                                  ? "pending"
                                  : isActive
                                  ? "bg-[#e7eaee] !text-black"
                                  : ""
                              } text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] block transition duration-300 text-[#8390A0] hover:text-black`
                            }
                          >
                            Tire Condition
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${active !== 6 && "group"} relative`}>
                <button
                  className="flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 overflow-hidden"
                  type="button"
                  onClick={() => setAccordian(6)}
                >
                  <img
                    src={Dispatch}
                    alt="Dispatch"
                    className="w-[18px] mr-4 shrink-0 opacity-50"
                  />
                  <span> Dispatch</span>
                </button>
                <div
                  className={`${
                    active === 6 ? "h-[120px]" : "h-0 "
                  }  transition-all duration-300`}
                >
                  <div
                    className={` ${
                      active !== 6 && " hidden"
                    } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                  >
                    <div className="pl-[33px] pt-0 group-hover:!p-4">
                      <ul>
                        <li
                          data-title="Routes"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Routes
                          </span>
                        </li>
                        <li
                          data-title="Recurring Routes"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Recurring Routes
                          </span>
                        </li>
                        <li
                          data-title="Planned vs. Actual"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Planned vs. Actual
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${active !== 7 && "group"} relative`}>
                <button
                  className="flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 overflow-hidden"
                  type="button"
                  onClick={() => setAccordian(7)}
                >
                  <img
                    src={FuelEnergy}
                    alt="Fuel & Energy"
                    className="w-[18px] mr-4 shrink-0 opacity-50"
                  />
                  <span> Fuel & Energy</span>
                </button>
                <div
                  className={`${
                    active === 7 ? "h-[80px]" : "h-0 "
                  }  transition-all duration-300`}
                >
                  <div
                    className={` ${
                      active !== 7 && " hidden"
                    } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                  >
                    <div className="pl-[33px] pt-0 group-hover:!p-4">
                      <ul>
                        <li
                          data-title="Fuel Purchases"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Fuel Purchases
                          </span>
                        </li>
                        <li
                          data-title="Eco-Driving"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Eco-Driving
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`relative`} data-title="Documents">
                <button
                  className="flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 overflow-hidden"
                  type="button"
                  onClick={() => setAccordian(8)}
                >
                  <img
                    src={Documents}
                    alt="Documents"
                    className="w-[18px] mr-4 shrink-0 opacity-50"
                  />
                  <span> Documents</span>
                </button>
              </div>
              <div className={`${active !== 9 && "group"} relative`}>
                <button
                  className="flex items-center text-[16px] p-2 hover:bg-[#e7eaee] rounded-[6px] w-full transition duration-300 overflow-hidden"
                  type="button"
                  onClick={() => setAccordian(9)}
                >
                  <img
                    src={Reports}
                    alt="Reports"
                    className="w-[18px] mr-4 shrink-0 opacity-50"
                  />
                  <span> Reports</span>
                </button>
                <div
                  className={`${
                    active === 9 ? "h-[160px]" : "h-0 "
                  }  transition-all duration-300`}
                >
                  <div
                    className={` ${
                      active !== 9 && " hidden"
                    } group-hover:absolute group-hover:[&_span]:!block left-full top-0 group-hover:!block group-hover:!visible group-hover:bg-white group-hover:rounded-[6px] group-hover:shadow group-hover:min-w-[200px]`}
                  >
                    <div className="pl-[33px] pt-0 group-hover:!p-4">
                      <ul>
                        <li
                          data-title="Dashboard"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Dashboard
                          </span>
                        </li>
                        <li
                          data-title="Fleet Benchmarks"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Fleet Benchmarks
                          </span>
                        </li>
                        <li
                          data-title="Custom Reports"
                          className="text-[16px] p-2 hover:bg-[#e7eaee] cursor-pointer rounded-[6px] w-full transition duration-300"
                        >
                          <span className="text-[#8390A0] hover:text-black">
                            Custom Reports
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <hr
              className={`mt-[15px] border-t !border-[#e7eaee] ${
                !sidebar && "hidden"
              }`}
            />
          </div>
          <div
            className={`flex flex-col justify-center items-center gap-2 ${
              !sidebar && "hidden"
            } pt-3`}
          >
            <div
              data-title="Messages"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Messages"
              className="cursor-pointer pb-3"
            >
              <img
                src={Messages}
                alt="Messages"
                className="w-[24px] h-[24px] opacity-50"
              />
            </div>
            <div
              data-title="Alerts"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Alerts"
              className="cursor-pointer pb-3"
            >
              <img
                src={Alerts}
                alt="Alerts"
                className="w-[24px] h-[24px] opacity-50"
              />
            </div>
            <Menu as="div" className="relative">
              <Menu.Button as="div" className="p-0 bg-transparent border-none">
                <div className="cursor-pointer pb-3">
                  <img
                    src={HelpFeedback}
                    alt="help & Feedback"
                    className="w-[24px] opacity-50"
                  />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-[15px] bottom-full bg-white rounded-[8px] mt-2 z-[999] origin-bottom-left min-w-[100px] shadow-[0_4px_8px_rgba(0,0,0,.15)] border-none">
                  <Menu.Item as="div" className="flex">
                    <NavLink
                      to="/"
                      className={({ isActive, isPending }) =>
                        `${
                          isPending ? "pending" : isActive ? "actives" : ""
                        } px-4 py-2 text-[14px] text-heading`
                      }
                    >
                      Send Feedback
                    </NavLink>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
            <NavLink
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Settings"
              to="/settings/general"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "actives" : ""
                } cursor-pointer pb-6`
              }
            >
              <img
                src={Settings}
                alt="Settings"
                className="w-[24px] opacity-50"
              />
            </NavLink>
          </div>

          <div className="mt-auto px-3 md:block hidden">
            <Menu as="div" className="relative">
              <Menu.Button
                as="div"
                className={`p-2 bg-white rounded-[6px] !border !border-[#e7eaee] flex justify-between items-center w-full cursor-pointer ${
                  sidebar && "!p-0 !bg-transparent !border-none"
                }`}
              >
                <h6
                  className={`text-[16px] font-semibold text-black ${
                    sidebar && "hidden"
                  } text-nowrap`}
                >
                  Company name
                </h6>
                <div className="w-[28px] h-[28px] rounded-full flex items-center justify-center bg-primary text-[12px] font-semibold relative text-white">
                  M
                  <span className="w-[12px] h-[12px] rounded-full border bg-red-700 absolute top-0 -right-1"></span>
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-[10px] bottom-[60px] bg-white rounded-[8px] mt-2 w-[170px] z-[999] origin-bottom-left min-w-[260px] shadow-[0_4px_8px_rgba(0,0,0,.15)] border-none">
                  <Menu.Item as="div" className="flex">
                    <span className={` px-4 py-3`}>
                      <h6 className="text-[15px] font-semibold text-black">
                        User name
                      </h6>
                      <p className="text-[14px] text-paragraph">
                        transport@icsukltd.co.uk
                      </p>
                    </span>
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <hr className="h-[1px] my-[5px] w-full border-none bg-[#e7eaee]" />
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <span className={` px-4 py-2`}>
                      <p className="text-[14px] text-heading flex items-center gap-2">
                        <img
                          src={Profile}
                          alt="Profile"
                          className="w-[18px] h-[18px] opacity-50"
                        />
                        Profile
                      </p>
                    </span>
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <hr className="h-[1px] my-[5px] w-full border-none bg-[#e7eaee]" />
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <span className={`px-4 py-2`}>
                      <p className="text-[14px] text-heading flex items-center gap-2">
                        <img
                          src={Billing}
                          alt="Billing"
                          className="w-[18px] h-[18px] opacity-50"
                        />
                        Billing
                      </p>
                    </span>
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <span className={` px-4 py-2`}>
                      <p className="text-[14px] text-heading flex items-center gap-2">
                        <img
                          src={Orders}
                          alt="Orders"
                          className="w-[18px] h-[18px] opacity-50"
                        />
                        Orders
                      </p>
                    </span>
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <span className={` px-4 py-2`}>
                      <p className="text-[14px] text-heading flex items-center gap-2">
                        <img
                          src={ActivateDevices}
                          alt="Activate devices"
                          className="w-[18px] h-[18px] opacity-50"
                        />
                        Activate devices
                      </p>
                    </span>
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <span className={` px-4 py-2`}>
                      <p className="text-[14px] text-heading flex items-center gap-2">
                        <img
                          src={OrganizationSettings}
                          alt="Organization settings"
                          className="w-[18px] h-[18px] opacity-50"
                        />
                        Organization settings
                      </p>
                    </span>
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <NavLink to="/" className={` px-4 py-2`}>
                      <p className="text-[14px] text-heading flex items-center gap-2">
                        <img
                          src={Logout}
                          alt="Logout"
                          className="w-[18px] h-[18px] opacity-50"
                        />
                        Sign out
                      </p>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <hr className="h-[1px] my-[5px] w-full border-none bg-[#e7eaee]" />
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <span className={` px-4 py-2`}>
                      <p className="text-[14px] text-heading flex items-center gap-2">
                        View My Organizations
                      </p>
                    </span>
                  </Menu.Item>
                  <Menu.Item as="div" className="flex">
                    <hr className="h-[1px] my-[5px] w-full border-none bg-[#e7eaee]" />
                  </Menu.Item>
                  <Menu.Item as="div" className="flex items-center px-4 py-2">
                    <span
                      className={` text-[14px] text-heading hover:text-heading`}
                    >
                      Terms of Service
                    </span>
                    <span className="inline-block mx-[8px] text-paragraph text-[14px]">
                      •
                    </span>
                    <span
                      className={` text-[14px] text-heading hover:text-heading`}
                    >
                      Privacy
                    </span>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div>
            <hr
              className={`mt-[15px] ${
                sidebar !== true ? "border-t !border-[#e7eaee]" : "border-none"
              } `}
            />
          </div>
          <div className="flex justify-center items-center">
            <Menu as="div" className="relative">
              <Menu.Button
                as="div"
                className={`pl-3 py-0 pr-0 bg-transparent border-none ${
                  sidebar && "hidden"
                }`}
              >
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="help & Feedback"
                  className="cursor-pointer pl-3 py-[12px]"
                >
                  <img
                    src={HelpFeedback}
                    alt="help & Feedback"
                    className="w-[24px] opacity-50"
                  />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-[15px] bottom-full bg-white rounded-[8px] mt-2 z-[999] origin-bottom-left min-w-[150px] shadow-[0_4px_8px_rgba(0,0,0,.15)] border-none">
                  <Menu.Item as="div" className="flex">
                    <NavLink
                      to="/"
                      className={({ isActive, isPending }) =>
                        `${
                          isPending ? "pending" : isActive ? "actives" : ""
                        } px-4 py-2 text-[14px] text-heading`
                      }
                    >
                      Send Feedback
                    </NavLink>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
            <span
              className={`mx-[5px] text-[#8390A0] text-[14px] ${
                sidebar && "hidden"
              }`}
            >
              •
            </span>
            <NavLink
              to="/settings/general"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "actives" : ""
                } cursor-pointer py-[12px] ${sidebar && "hidden"}`
              }
            >
              <img
                src={Settings}
                alt="Settings"
                className="w-[24px] opacity-50"
              />
            </NavLink>
            <span
              className={`mx-[5px] text-[#8390A0] text-[14px] ${
                sidebar && "hidden"
              }`}
            >
              •
            </span>
            <p
              data-title="Feedback"
              className={`text-[15px] text-paragraph mr-auto ${
                sidebar && "hidden"
              }`}
            >
              Feedback
            </p>
            <div
              id="toggle-sidebar"
              onClick={() => {
                toggleSidebar();
                setActive(0);
              }}
              className={`${
                !sidebar && "border-l border-[#dddfe1]"
              }  px-2 h-full py-[12px] cursor-pointer md:block hidden`}
            >
              <img
                src={Right}
                alt="Right"
                className={`${
                  sidebar && "rotate-180"
                } w-[24px] opacity-50 shrink-0`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
