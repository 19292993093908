import SettingsSidebar from "../../../../components/SettingsSidebar";
import DriverActivityTab from "../components/DriverActivityTab";
export default function VehicleFuelType() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold mb-8">
          Driver Activity
        </h1>
        <DriverActivityTab />
        <div class="border border-[#e7eaee] rounded-[4px] p-[24px] mt-[24px]">
          <h2 class="text-[19px] text-black font-semibold mb-[22px]">
            Service Hours
          </h2>
          <p class="text-[15px] text-black">
            Working and rest hours for the Timesheet Report will be added by:
          </p>
          <select className="w-full h-[40px] border rounded-md px-3 outline-0 mt-2 max-w-[35%]">
            <option>Driver App & Tachograph</option>
          </select>
        </div>
      </div>
    </div>
  );
}
