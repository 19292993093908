import SettingsSidebar from "../../../../components/SettingsSidebar";
import DriverActivityTab from "../components/DriverActivityTab";
import { AiOutlinePlus } from "react-icons/ai";
export default function VehicleFuelType() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold mb-8">
          Driver Activity
        </h1>
        <DriverActivityTab />
        <h5 className="text-[16px] font-medium text-black mt-6">
          Configure drivers’ default working hours: working days, start time,
          stop time, and default time zone. Configuration is individually or by
          tag
        </h5>
        <button className="!px-4 !py-2 text-white bg-[#0d6ede] rounded-[2px] flex items-center gap-2 mt-6">
          <AiOutlinePlus size={14} className="text-white" />
          Add working hours
        </button>
      </div>
    </div>
  );
}
