import React, { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { AiFillCaretDown } from "react-icons/ai";
import { HiOutlineSearch } from "react-icons/hi";
import { BiCalendar } from "react-icons/bi";

export default function Filters() {
  return (
    <div className="flex items-center gap-2 divide-x divide-[#e7eaee] py-[10px]">
      <div className="flex items-center gap-3 cursor-pointer">
        <FiChevronLeft size={20} />
        <div className="flex items-center gap-2 relative ">
          <BiCalendar size={20} />
          <sapn>Today</sapn>
          <input
            type="datetime-local"
            className="absolute opacity-0 w-[unset] max-w-[unset] inset-0"
          />
        </div>
        <FiChevronRight size={20} />
      </div>
      <Menu as="div" className="relative">
        <Menu.Button
          as="div"
          className="flex items-center gap-2 cursor-pointer hover:bg-inverted p-2 rounded-md"
        >
          <span>Event Type</span>
          <AiFillCaretDown size={14} className="text-paragraph" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[300px] origin-top-left rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none border border-[#e7eaee]">
            <div className="p-[16px] border-b">
              <div className="relative">
                <input
                  className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
                  placeholder="Search Options"
                />
                <HiOutlineSearch
                  size={16}
                  className="absolute left-[7px] top-[14px] text-paragraph"
                />
              </div>
            </div>
            <div className="p-[16px]">
              <h6 className="text-[14px] text-black font-semibold">
                Event Type
              </h6>
              <div className="mt-3 flex flex-col gap-3">
                <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                  <input
                    type="checkbox"
                    name="termOfUse"
                    className="hidden peer"
                  />
                  <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                  Walkaround
                </label>
                <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                  <input
                    type="checkbox"
                    name="termOfUse"
                    className="hidden peer"
                  />
                  <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                  DVIR defect
                </label>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <Menu as="div" className="relative !pl-2">
        <Menu.Button
          as="div"
          className="flex items-center gap-2 cursor-pointer hover:bg-inverted p-2 rounded-md"
        >
          <span>Actions</span>
          <AiFillCaretDown size={14} className="text-paragraph" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[300px] origin-top-left rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none border border-[#e7eaee]">
            <div className="p-[16px] border-b">
              <div className="relative">
                <input
                  className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
                  placeholder="Search Options"
                />
                <HiOutlineSearch
                  size={16}
                  className="absolute left-[7px] top-[14px] text-paragraph"
                />
              </div>
            </div>
            <div className="p-[16px]">
              <h6 className="text-[14px] text-black font-semibold">Actions</h6>
              <div className="mt-3 flex flex-col gap-3">
                <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                  <input
                    type="checkbox"
                    name="termOfUse"
                    className="hidden peer"
                  />
                  <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                  Accepted
                </label>
                <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                  <input
                    type="checkbox"
                    name="termOfUse"
                    className="hidden peer"
                  />
                  <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                  Acknowledged
                </label>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <div className="ml-auto border-none flex items-center">
        <div className="relative">
          <input
            className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
            placeholder="Search by user email"
          />
          <HiOutlineSearch
            size={16}
            className="absolute left-[7px] top-[14px] text-paragraph"
          />
        </div>

        <Menu as="div" className="relative !pl-2 h-[45px]">
          <Menu.Button
            as="div"
            className="flex items-center gap-2 cursor-pointer hover:bg-inverted p-2 rounded-md h-full"
          >
            <svg
              className="fill-paragraph w-[20px]"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M10 18h5V5h-5v13zm-6 0h5V5H4v13zM16 5v13h5V5h-5z"></path>
            </svg>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-[300px] origin-top-right rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none">
              <div className="p-[16px]">
                <h6 className="text-[14px] text-black font-semibold">
                  Columns
                </h6>
                <div className="mt-3 flex flex-col gap-3">
                  <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                    <input
                      type="checkbox"
                      name="termOfUse"
                      className="hidden peer"
                    />
                    <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                    User
                  </label>
                  <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                    <input
                      type="checkbox"
                      name="termOfUse"
                      className="hidden peer"
                    />
                    <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                    Event
                  </label>
                </div>
                <hr className="my-3 !border-[#e7eaee]" />
                <div className="flex items-center gap-2 justify-between">
                  <button className="!px-4 !py-2 text-[#5a5a5a] !border !border-[#caced3] bg-white rounded-[2px]">
                    Reset
                  </button>
                  <div className="flex items-center gap-2">
                    <button className="!px-4 !py-2 text-[#5a5a5a] !border !border-[#caced3] bg-white rounded-[2px]">
                      Cancel
                    </button>
                    <button className="!px-4 !py-2 text-[#5a5a5a] !border !border-[#caced3] bg-[#caced3]/30 rounded-[2px]">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
