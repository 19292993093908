export default function Summary() {
  return (
    <>
      <div className="!border !border-[#e7eaee] rounded-[4px] p-[24px] mt-[24px]">
        <h2 className="text-[20px] text-black font-semibold mb-[24px]">
          Not currently available
        </h2>
        <p className="text-[16px] text-black">
          Billing features are not currently available.
        </p>
      </div>
      <div className="!border !border-[#e7eaee] rounded-[4px] p-[24px] mt-[24px]">
        <h2 className="text-[20px] text-black font-semibold mb-[24px]">Help</h2>
        <p className="text-[16px] text-black">
          For questions about billing or your invoices,{" "}
          <a href="#" className="underline">
            please submit a support ticket.
          </a>
        </p>
      </div>
    </>
  );
}
