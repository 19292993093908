import { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { BsFillTagFill } from "react-icons/bs";
import { AiFillCaretDown } from "react-icons/ai";
import { HiOutlineSearch } from "react-icons/hi";
import { MdRemoveRedEye } from "react-icons/md";

export default function OverviewHeader() {
  return (
    <div className="flex items-center gap-2 pt-[16px]">
      <Menu as="div" className="relative">
        <Menu.Button
          as="div"
          className="flex items-center gap-2 text-[14px] cursor-pointer hover:bg-inverted p-2 rounded-md"
        >
          <BsFillTagFill size={18} className="text-paragraph" />
          <span className="text-paragraph font-medium">Tags</span>
          <AiFillCaretDown size={10} className="text-paragraph" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[300px] origin-top-left rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none border border-[#e7eaee]">
            <div className="p-[16px] border-b">
              <div className="relative">
                <input
                  className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
                  placeholder="Search Options"
                />
                <HiOutlineSearch
                  size={16}
                  className="absolute left-[7px] top-[14px] text-paragraph"
                />
              </div>
            </div>
            <div className="p-[16px]">
              <div className="relative">
                <p className="text-[14px] font-medium text-heading">
                  No tags in this organization.{" "}
                  <a href="#" className="text-[#0d6ede]">
                    Manage
                  </a>
                </p>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <Menu as="div" className="relative pl-2">
        <Menu.Button
          as="div"
          className="flex items-center gap-2 text-[14px] cursor-pointer hover:bg-inverted p-2 rounded-md"
        >
          <span className="text-paragraph font-medium">More</span>
          <AiFillCaretDown size={10} className="text-paragraph" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[300px] origin-top-left rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none border border-[#e7eaee]">
            <div className="p-[14px] border-b">
              <div className="relative">
                <input
                  className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
                  placeholder="Search options"
                />
                <HiOutlineSearch
                  size={16}
                  className="absolute left-[7px] top-[14px] text-paragraph"
                />
              </div>
            </div>
            <div className="p-[14px_14px_0]">
              <h6 className="text-[14px] mt-2 text-black font-semibold">
                Asset Status
              </h6>
            </div>
            <label className="cursor-pointer flex items-center text-[14px] font-medium p-[14px] hover:bg-inverted">
              <input type="checkbox" name="termOfUse" className="hidden peer" />
              <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
              Online
            </label>
            <div className="px-[14px]">
              <h6 className="text-[14px] text-black font-semibold">
                Asset Name
              </h6>
            </div>
            <label className="cursor-pointer flex items-center text-[14px] font-medium p-[14px] hover:bg-inverted">
              <input type="checkbox" name="termOfUse" className="hidden peer" />
              <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
              GA6D-PMG-UJJ
            </label>
          </Menu.Items>
        </Transition>
      </Menu>
      <Menu as="div" className="relative pl-2">
        <Menu.Button
          as="div"
          className="flex items-center gap-2 text-[14px] cursor-pointer hover:bg-inverted p-2 rounded-md"
        >
          <span className="text-paragraph font-medium">Sort</span>
          <AiFillCaretDown size={10} className="text-paragraph" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[160px] origin-top-left rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none">
            <h5 className="cursor-pointer flex items-center text-[14px] font-medium p-[8px_14px] hover:bg-inverted">
              In Motion
            </h5>
            <h5 className="cursor-pointer flex items-center text-[14px] font-medium p-[8px_14px] hover:bg-inverted">
              A → Z
            </h5>
            <h5 className="cursor-pointer flex items-center text-[14px] font-medium p-[8px_14px] hover:bg-inverted">
              Z → A
            </h5>{" "}
            <h5 className="cursor-pointer flex items-center text-[14px] font-medium p-[8px_14px] hover:bg-inverted">
              In Risk Zone
            </h5>
          </Menu.Items>
        </Transition>
      </Menu>
      <Menu as="div" className="relative pl-2 ml-auto">
        <Menu.Button
          as="div"
          className="flex items-center gap-2 text-[14px] cursor-pointer hover:bg-inverted p-2 rounded-md"
        >
          <MdRemoveRedEye size={20} className="text-paragraph" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[300px] origin-top-left rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none border border-[#e7eaee]">
            <div className="p-[14px] border-b">
              <div className="relative">
                <input
                  className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
                  placeholder="Search options"
                />
                <HiOutlineSearch
                  size={16}
                  className="absolute left-[7px] top-[14px] text-paragraph"
                />
              </div>
            </div>
            <div className="p-[14px_14px_0]">
              <h6 className="text-[14px] mt-2 text-black font-semibold">
                Asset Information
              </h6>
            </div>
            <label className="cursor-pointer flex items-center text-[14px] font-medium p-[14px] hover:bg-inverted">
              <input type="checkbox" name="termOfUse" className="hidden peer" />
              <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
              Fuel / Energy level
            </label>
            <div className="px-[14px]">
              <h6 className="text-[14px] text-black font-semibold">Other</h6>
            </div>
            <label className="cursor-pointer flex items-center text-[14px] font-medium p-[14px] hover:bg-inverted">
              <input type="checkbox" name="termOfUse" className="hidden peer" />
              <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
              Device Issues
            </label>
            <div className="p-[8px_14px] bg-inverted">
              <button className="text-primary text-[14px] inline-block font-semibold">
                Reset
              </button>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
