import { RxCounterClockwiseClock } from "react-icons/rx";
import SettingsSidebar from "../../../../components/SettingsSidebar";
import DriverActivityTab from "../components/DriverActivityTab";
import { AiOutlinePlus } from "react-icons/ai";
export default function Overtime() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold mb-8">
          Driver Activity
        </h1>
        <DriverActivityTab />
        <div class="border border-[#e7eaee] rounded-[4px] p-[24px] mt-[24px]">
          <RxCounterClockwiseClock
            size={24}
            className="text-primary mb-4 mt-2"
          />
          <h2 class="text-[19px] text-black font-medium mb-[4px]">
            Overtime Policy
          </h2>
          <p class="text-[15px] text-black mb-4">
            Configure minimum working duration to trigger overtime and the
            corresponding rates
          </p>
          <button className="px-4 py-2 text-white text-[14px] bg-[#0d6ede] rounded-[2px] flex items-center gap-2 mt-4">
            <AiOutlinePlus size={14} className="text-white" />
            Create Policy
          </button>
        </div>
      </div>
    </div>
  );
}
