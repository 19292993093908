import React, { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DriverHeader from "../DevicesHeader";

export default function ActiveDrivers() {
  return (
    <>
      <DriverHeader />
      <div className="w-full">
        <div className="min-w-[800px]">
          <table className="w-full border-b border-t border-[#e7eaee]">
            <thead className="border-b border-t border-[#e7eaee]">
              <tr>
                <th
                  data-title="name"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                    <input
                      type="checkbox"
                      name="termOfUse"
                      className="hidden peer"
                    />
                    <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                  </label>
                </th>
                <th
                  data-title="name"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Gateway Serial
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Gateway
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Name
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Data Used (This Month)
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Camera
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Camera Serial
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Connectivity
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Battery
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Device Calibration Status
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Power State
                  </span>
                </th>
                <th
                  data-title="vehicleLocation"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">
                    <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                      <input
                        type="checkbox"
                        name="termOfUse"
                        className="hidden peer"
                      />
                      <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                    </label>
                  </div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">G2PE-753-V4A</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">VG54-EU</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">YK22WMJ</div>
                </td>
                <td className="p-[24px_8px]"></td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">CM31</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">GVRR-77C-J6T</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true"></div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true"></div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">Calibrated</div>
                </td>
                <td className="p-[24px_8px]"></td>
                <td className="p-[24px_8px]">
                  <Menu as="div" className="relative">
                    <Menu.Button
                      as="div"
                      className="flex items-center justify-end gap-2 cursor-pointer hover:bg-inverted p-2 rounded-md"
                    >
                      <BiDotsHorizontalRounded
                        size={22}
                        className="text-paragraph"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-[170px] origin-top-right rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none">
                        <Menu.Item
                          as="div"
                          className="p-[10px_16px] hover:bg-gray-100 cursor-pointer"
                        >
                          Settings
                        </Menu.Item>
                        <Menu.Item
                          as="div"
                          className="p-[10px_16px] hover:bg-gray-100 cursor-pointer"
                        >
                          Deactivate Gateway
                        </Menu.Item>
                        <Menu.Item
                          as="div"
                          className="p-[10px_16px] hover:bg-gray-100 cursor-pointer"
                        >
                          Pair Gateway
                        </Menu.Item>
                        <Menu.Item
                          as="div"
                          className="p-[10px_16px] hover:bg-gray-100 cursor-pointer"
                        >
                          Request Support
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
