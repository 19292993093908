import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function AddDriverPopup({ isOpen, closeModal }) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[600px] transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <div className="p-6">
                    <h4 className="text-[20px] text-black font-semibold mb-2">
                      Create a new driver
                    </h4>
                    <p className="text-paragraph text-[15px] font-medium">
                      Follow these instructions to create a new driver for your
                      fleet. Once created, the driver will be able to access the
                      Driver App with the chosen credentials.
                    </p>
                  </div>
                  <hr className=" !border-[#e7eaee]" />

                  <div className="px-6 py-4">
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Driver Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Driver Phone Number
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Driver Card Number
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Driver License Number
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Driver License State or Province
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Driver License State or Province</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Tags
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select Tags</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Notes
                      </label>
                      <textarea
                        type="text"
                        rows="2"
                        className="w-full border rounded-md px-3 outline-0"
                      ></textarea>
                    </div>
                    <hr className="my-3 !border-[#e7eaee]" />
                    <h4 className="text-[22px] text-black font-semibold mb-2">
                      Hours of Service
                    </h4>
                    <hr className="my-3 !border-[#e7eaee]" />
                    <h4 className="text-[18px] text-black font-semibold mb-3">
                      Carrier Information
                    </h4>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Home Terminal Name
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Home Terminal Address
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Home Terminal Timezone
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select a Timezone</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Carrier / Principal Place of Business Name Override
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Main Office / Principal Place of Business Address
                        Override
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <hr className="my-3 !border-[#e7eaee]" />
                    <h4 className="text-[22px] text-black font-semibold mb-3">
                      Advanced
                    </h4>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Locale Override
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select a Locale</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Driver Peer Group
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select peer group</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Static Vehicle Assignment
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select vehicle</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Vehicle Selection Tag
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select vehicle tag</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Trailer Selection Tag
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select trailer tag</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Driver ID Token
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Unassigned</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Start of Day Workflow
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select start of day workflow</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        End of Day Workflow
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select end of day workflow</option>
                      </select>
                    </div>
                  </div>
                  <hr className=" !border-[#e7eaee]" />
                  <div className="px-6 py-4 flex items-center gap-2 justify-end">
                    <button
                      onClick={closeModal}
                      className="!px-4 !py-2 text-[#5a5a5a] !border !border-[#caced3] bg-white rounded-[2px] flex items-center gap-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={closeModal}
                      className="!px-4 !py-2 text-white bg-[#0d6ede] rounded-[2px] flex items-center gap-2"
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
