import Filters from "./components/Filters";

export default function UnassignedDriving() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold">
          Unassigned Driving Report
        </h1>
        <p className="text-paragraph text-[15px] mt-1 mb-8 font-medium">
          1 May 2023 00:00 - 1 May 2023 23:59
        </p>
        <hr className="border-[#e7eaee]" />
        <Filters />
        <hr className="border-[#e7eaee]" />
        <div className="w-full my-4">
          <p className="text-[16px] text-heading">
            There were no unassigned driving logs during this period.
          </p>
        </div>
        <hr className="border-[#e7eaee]" />
      </div>
    </div>
  );
}
