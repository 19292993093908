import Filters from "./components/Filters";
import { NavLink } from "react-router-dom";
import { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";

export default function Defects() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="mb-8 flex justify-between items-start">
          <div className="">
            <h1 className="text-[26px] text-black font-bold mb-1">
              Walkaround Templates
            </h1>
            <p className="text-[#657281] text-[15px] font-medium">
              Set up how you want your drivers to submit walkarounds.
            </p>
          </div>
          <button className="flex items-center justify-end gap-2 cursor-pointer bg-white !px-2 !py-2 rounded-md !border !border-[#c1c9d1]">
            <AiOutlinePlus size={14} />
            Create template
          </button>
        </div>
        <div className="border-b border-[#e7eaee] flex items-center">
          <NavLink
            to="/walkaround/templates"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Templates
          </NavLink>
          <NavLink
            to="/walkaround/assignments"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Assignments
          </NavLink>
        </div>
        <Filters />

        <div className="w-full">
          <div className="min-w-[800px]">
            <table className="w-full border-b border-t border-[#e7eaee]">
              <thead className="border-b border-t border-[#e7eaee]">
                <tr>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Template Name</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Available Defects</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">
                      {" "}
                      Vehicles/Trailers Using This Template
                    </span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-[24px_8px]">Heavy Goods Vehicle</td>
                  <td className="p-[24px_8px]">25</td>
                  <td className="p-[24px_8px]">4</td>
                  <td className="p-[24px_8px]">
                    <Menu as="div" className="relative">
                      <Menu.Button
                        as="div"
                        className="flex items-center justify-end gap-2 cursor-pointer bg-white !px-2 !py-2"
                      >
                        <BiDotsHorizontalRounded
                          size={22}
                          className="text-black"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 mt-2 w-[170px] z-[999] origin-top-right rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none">
                          <Menu.Item
                            as="div"
                            className="p-[8px_16px] text-[14px] hover:bg-gray-100 cursor-pointer"
                          >
                            Edit
                          </Menu.Item>
                          <Menu.Item
                            as="div"
                            className="p-[8px_16px] text-[14px] hover:bg-gray-100 cursor-pointer"
                          >
                            Delete template
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
