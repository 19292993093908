import Filters from "./components/Filters";

export default function MaintenanceStatus() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="flex justify-between items-center">
          <h1 className="text-[26px] text-black font-bold mb-8">
            Maintenance Status
          </h1>
        </div>
        <hr className="border-[#e7eaee]" />
        <Filters />
        <div className="w-full">
          <div className="min-w-[800px]">
            <table className="w-full border-b border-t border-[#e7eaee]">
              <thead className="border-b border-t border-[#e7eaee]">
                <tr>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Asset</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Current Driver</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Make/Model</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Battery Voltage</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Engine Hours</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Odometer (Mi)</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Check Engine Light</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Defects</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Faults</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-[24px_8px]">Tacho Reader</td>
                  <td className="p-[24px_8px]">-</td>
                  <td className="p-[24px_8px]">-</td>
                  <td className="p-[24px_8px]">0.4</td>
                  <td className="p-[24px_8px]">-</td>
                  <td className="p-[24px_8px]">158,357</td>
                  <td className="p-[24px_8px]">Off</td>
                  <td className="p-[24px_8px]">-</td>
                  <td className="p-[24px_8px]">-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
