import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import SearchModal from "../components/SearchModal";

// Auth routes
import LoginScreen from "../pages/Auth/LoginScreen";
import RegisterScreen from "../pages/Auth/RegisterScreen";
import ResetPasswordScreen from "../pages/Auth/ResetPasswordScreen";

// General routes
import OverviewScreen from "../pages/General/OverviewScreen";
import AssetsScreen from "../pages/General/AssetsScreen";
import DriversScreen from "../pages/General/DriversScreen";
import CoverageMapScreen from "../pages/General/CoverageMapScreen";
import ProximityScreen from "../pages/General/ProximityScreen";
import ComplianceHistoricalScreen from "../pages/General/ComplianceHistoricalScreen";
import ComplianceLiveScreen from "../pages/General/ComplianceLiveScreen";
import InfringementScreen from "../pages/General/InfringementScreen";
import TachnographDriverScreen from "../pages/General/TachnographDriverScreen";
import TachnographVehiclesScreen from "../pages/General/TachnographVehiclesScreen";
import TachnographExplorerScreen from "../pages/General/TachnographExplorerScreen";
import UnassignedDrivingScreen from "../pages/General/UnassignedDrivingScreen";
import MaintenanceStatusScreen from "../pages/General/MaintenanceStatusScreen";
import ServiceLogsScreen from "../pages/General/ServiceLogsScreen";
import WalkaroundInspectionReportsDefectsScreen from "../pages/General/WalkaroundInspectionReportsDefectsScreen";
import WalkaroundInspectionReportsWalkaroundsScreen from "../pages/General/WalkaroundInspectionReportsWalkaroundsScreen";
import WalkaroundTemplatesScreen from "../pages/General/WalkaroundTemplatesScreen";
import WalkaroundAssignmentsScreen from "../pages/General/WalkaroundAssignmentsScreen";
import SchedulesMaintenanceScreen from "../pages/General/SchedulesMaintenanceScreen";
import UpcomingMaintenanceScreen from "../pages/General/UpcomingMaintenanceScreen";
import TireConditionMaintenanceScreen from "../pages/General/TireConditionMaintenanceScreen";

// Settings routes
import GeneralScreen from "../pages/Settings/GeneralScreen";
import SettingsUserScreen from "../pages/Settings/UserRolesScreen/Users";
import SettingsRolesScreen from "../pages/Settings/UserRolesScreen/Roles";
import SettingsPendingInvitationsScreen from "../pages/Settings/UserRolesScreen/PendingInvitations";
import ActivateDriversScreen from "../pages/Settings/DriversScreen/ActivateDriver";
import DeActivateDriversScreen from "../pages/Settings/DriversScreen/DeActivateDriver";
import DriversCSVUploadsScreen from "../pages/Settings/DriversScreen/CSVUploads";
import TagsScreen from "../pages/Settings/TagsScreen/Tags";
import TagsUploadScreen from "../pages/Settings/TagsScreen/TagsUpload";
import ActivityLogScreen from "../pages/Settings/ActivityLogScreen";
import BillingSummaryScreen from "../pages/Settings/BillingScreen/Summary";
import BillingInvoicesScreen from "../pages/Settings/BillingScreen/Invoices";
import DevicesGatewaysScreen from "../pages/Settings/DevicesScreen/Gateways";
import DevicesCamerasScreen from "../pages/Settings/DevicesScreen/Cameras";
import DevicesSensorsScreen from "../pages/Settings/DevicesScreen/Sensors";
import DispatchScreen from "../pages/Settings/DispatchScreen";
import EcoDrivingScreen from "../pages/Settings/FuelEnergy/EcoDrivingScreen";
import FuelCardsScreen from "../pages/Settings/FuelEnergy/FuelCardsScreen";
import VehicleFuelTypeScreen from "../pages/Settings/FuelEnergy/VehicleFuelTypeScreen";
import FuelCostScreen from "../pages/Settings/FuelEnergy/FuelCostScreen";
import FuelPurchaseScreen from "../pages/Settings/FuelEnergy/FuelPurchaseScreen";
import WorkingHoursScreen from "../pages/Settings/DriverActivityScreen/WorkingHours";
import MaxDistanceScreen from "../pages/Settings/DriverActivityScreen/MaxDistance";
import TimesheetScreen from "../pages/Settings/DriverActivityScreen/Timesheet";
import OvertimeScreen from "../pages/Settings/DriverActivityScreen/Overtime";
import TimeOffScreen from "../pages/Settings/DriverActivityScreen/TimeOff";
import ValidAddressesScreen from "../pages/Settings/AddressesScreen/ValidAddresses";
import InvalidAddressesScreen from "../pages/Settings/AddressesScreen/InvalidAddresses";

export default function AppRoutes() {
  const [mobile, setMobile] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [route, setRoute] = useState("");
  const location = useLocation();
  useEffect(() => {
    setSidebarShow(location.pathname);
  }, [location]);

  const setSidebarShow = (param) => {
    param == "/"
      ? setRoute(false)
      : param == "/register"
      ? setRoute(false)
      : param == "/reset-password"
      ? setRoute(false)
      : setRoute(true);
  };
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };
  const mobileToggler = () => {
    setMobile(!mobile);
    setSidebar(false);
  };
  const openSearchModal = () => {
    setIsopen(true);
  };
  const dismissSearchModal = () => {
    setIsopen(false);
  };
  return (
    <>
      <Header mobileToggler={mobileToggler} openSearchModal={openSearchModal} />
      {route && (
        <Sidebar
          mobile={mobile}
          sidebar={sidebar}
          toggleSidebar={toggleSidebar}
          openSearchModal={openSearchModal}
        />
      )}
      <div className="h-screen">
        <div
          className={`grow h-full transition-all duration-300 ${
            !route && "!pl-0"
          } ${!sidebar ? "md:pl-[250px]" : "md:pl-[70px]"}`}
        >
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route path="/register" element={<RegisterScreen />} />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />
            <Route path="/overview" element={<OverviewScreen />} />
            <Route path="/assets" element={<AssetsScreen />} />
            <Route path="/drivers" element={<DriversScreen />} />
            <Route path="/coverage-map" element={<CoverageMapScreen />} />
            <Route path="/proximity" element={<ProximityScreen />} />
            <Route
              path="/compliance/historical"
              element={<ComplianceHistoricalScreen />}
            />
            <Route path="/compliance/live" element={<ComplianceLiveScreen />} />
            <Route path="/infringement" element={<InfringementScreen />} />
            <Route
              path="/tachnograph-downloads/driver"
              element={<TachnographDriverScreen />}
            />
            <Route
              path="/tachnograph-downloads/vehicles"
              element={<TachnographVehiclesScreen />}
            />
            <Route
              path="/tachnograph-explorer"
              element={<TachnographExplorerScreen />}
            />
            <Route
              path="/unassigned-driving"
              element={<UnassignedDrivingScreen />}
            />
            <Route
              path="/maintenance-status"
              element={<MaintenanceStatusScreen />}
            />
            <Route path="/service-log" element={<ServiceLogsScreen />} />
            <Route
              path="/walkaround-inspection-reports/defects"
              element={<WalkaroundInspectionReportsDefectsScreen />}
            />
            <Route
              path="/walkaround-inspection-reports/walkarounds"
              element={<WalkaroundInspectionReportsWalkaroundsScreen />}
            />
            <Route
              path="/walkaround/templates"
              element={<WalkaroundTemplatesScreen />}
            />
            <Route
              path="/walkaround/assignments"
              element={<WalkaroundAssignmentsScreen />}
            />
            <Route
              path="/schedules-maintenance"
              element={<SchedulesMaintenanceScreen />}
            />
            <Route
              path="/upcoming-maintenance"
              element={<UpcomingMaintenanceScreen />}
            />
            <Route
              path="/tire-conditions"
              element={<TireConditionMaintenanceScreen />}
            />
            <Route path="/settings/general" element={<GeneralScreen />} />
            <Route path="/settings/users" element={<SettingsUserScreen />} />
            <Route path="/settings/roles" element={<SettingsRolesScreen />} />
            <Route
              path="/settings/pending-invitation"
              element={<SettingsPendingInvitationsScreen />}
            />
            <Route
              path="/settings/activate-drivers"
              element={<ActivateDriversScreen />}
            />
            <Route
              path="/settings/deactivate-drivers"
              element={<DeActivateDriversScreen />}
            />
            <Route
              path="/settings/csv-uploads"
              element={<DriversCSVUploadsScreen />}
            />
            <Route path="/settings/tags" element={<TagsScreen />} />
            <Route
              path="/settings/tags-upload"
              element={<TagsUploadScreen />}
            />
            <Route
              path="/settings/activity-log"
              element={<ActivityLogScreen />}
            />
            <Route
              path="/settings/billing/summary"
              element={<BillingSummaryScreen />}
            />
            <Route
              path="/settings/billing/invoices"
              element={<BillingInvoicesScreen />}
            />
            <Route
              path="/settings/devices/gateways"
              element={<DevicesGatewaysScreen />}
            />
            <Route
              path="/settings/devices/cameras"
              element={<DevicesCamerasScreen />}
            />
            <Route
              path="/settings/devices/sensors"
              element={<DevicesSensorsScreen />}
            />
            <Route path="/settings/dispatch" element={<DispatchScreen />} />
            <Route
              path="/settings/fuel-energy/eco-driving"
              element={<EcoDrivingScreen />}
            />
            <Route
              path="/settings/fuel-energy/fuel-cards"
              element={<FuelCardsScreen />}
            />
            <Route
              path="/settings/fuel-energy/vehicle-fuel-types"
              element={<VehicleFuelTypeScreen />}
            />
            <Route
              path="/settings/fuel-energy/fuel-cost"
              element={<FuelCostScreen />}
            />
            <Route
              path="/settings/fuel-energy/fuel-purchase"
              element={<FuelPurchaseScreen />}
            />
            <Route
              path="/settings/driver-activity/working-hours"
              element={<WorkingHoursScreen />}
            />
            <Route
              path="/settings/driver-activity/max-distance"
              element={<MaxDistanceScreen />}
            />
            <Route
              path="/settings/driver-activity/timesheet"
              element={<TimesheetScreen />}
            />
            <Route
              path="/settings/driver-activity/overtime"
              element={<OvertimeScreen />}
            />
            <Route
              path="/settings/driver-activity/time-off"
              element={<TimeOffScreen />}
            />
            <Route
              path="/settings/addresses/valid-addresses"
              element={<ValidAddressesScreen />}
            />
            <Route
              path="/settings/addresses/invalid-addresses"
              element={<InvalidAddressesScreen />}
            />
          </Routes>
        </div>
      </div>
      <SearchModal isOpen={isOpen} dismissSearchModal={dismissSearchModal} />
    </>
  );
}
