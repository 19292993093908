import SettingsSidebar from "../../../../components/SettingsSidebar";
import TagsUploads from "./components/TagsUploads";
import { NavLink } from "react-router-dom";

export default function Tags() {
  return (
    <>
      <div className="flex">
        <SettingsSidebar />
        <div className="p-10 grow overflow-auto h-screen">
          <div className="flex justify-between items-center">
            <h1 className="text-[26px] text-black font-bold mb-8">Tags</h1>
          </div>
          <div>
            <div className="border-b border-[#e7eaee] flex items-center">
              <NavLink
                to="/settings/tags"
                className={({ isActive, isPending }) =>
                  `${
                    isPending
                      ? "pending"
                      : isActive
                      ? "!text-heading border-heading"
                      : "!text-[#4c5661] border-white"
                  } p-[16px]  outline-0 border-b-[1px] font-bold`
                }
              >
                Tags
              </NavLink>
              <NavLink
                to="/settings/tags-upload"
                className={({ isActive, isPending }) =>
                  `${
                    isPending
                      ? "pending"
                      : isActive
                      ? "!text-heading border-heading"
                      : "!text-[#4c5661] border-white"
                  } p-[16px]  outline-0 border-b-[1px] font-bold`
                }
              >
                Deactivated Drivers
              </NavLink>
            </div>
            <TagsUploads />
          </div>
        </div>
      </div>
    </>
  );
}
