import { NavLink } from "react-router-dom";

export default function DriverActivityTab() {
  return (
    <div className="border-b border-[#e7eaee] flex items-center">
      <NavLink
        to="/settings/driver-activity/working-hours"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Working Hours
      </NavLink>
      <NavLink
        to="/settings/driver-activity/max-distance"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Max Distance
      </NavLink>
      <NavLink
        to="/settings/driver-activity/timesheet"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Timesheet
      </NavLink>
      <NavLink
        to="/settings/driver-activity/overtime"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Overtime
      </NavLink>
      <NavLink
        to="/settings/driver-activity/time-off"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Time Off
      </NavLink>
    </div>
  );
}
