import React, { Fragment, useState } from "react";
import { Transition, Menu } from "@headlessui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import CreateTagPopup from "../CreateTagPopup";

export default function TagsTab() {
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <div>
        <div className="flex items-center justify-end gap-3 my-4">
          <button
            onClick={openModal}
            className="!px-4 !py-2 text-white bg-[#0d6ede] rounded-[2px] flex items-center gap-2"
          >
            <AiOutlinePlus size={14} className="text-white" />
            Create a Tag
          </button>
          <Menu as="div" className="relative">
            <Menu.Button
              as="div"
              className="flex items-center justify-end gap-2 cursor-pointer bg-white !px-2 !py-2 rounded-md !border !border-[#c1c9d1]"
            >
              <BiDotsHorizontalRounded size={22} className="text-paragraph" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-[170px] z-[999] origin-top-right rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none">
                <Menu.Item
                  as="div"
                  className="p-[8px_16px] text-[14px] hover:bg-gray-100 cursor-pointer"
                >
                  Upload CSV
                </Menu.Item>
                <Menu.Item
                  as="div"
                  className="p-[8px_16px] text-[14px] hover:bg-gray-100 cursor-pointer"
                >
                  Download CSV
                </Menu.Item>{" "}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <div className="w-full">
        <input
          type="text"
          placeholder="Search for tags or emails"
          className="w-full h-[40px] border rounded-md px-3 outline-0"
        />
      </div>
      <CreateTagPopup isOpen={isOpen} closeModal={closeModal} />
    </>
  );
}
