import Filters from "./components/Filters";
import { NavLink } from "react-router-dom";

export default function TachnographVehicles() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="flex justify-between items-center">
          <h1 className="text-[26px] text-black font-bold mb-8">
            Tachograph File Downloads
          </h1>
        </div>
        <div className="border-b border-[#e7eaee] flex items-center">
          <NavLink
            to="/tachnograph-downloads/driver"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Drivers 1
          </NavLink>
          <NavLink
            to="/tachnograph-downloads/vehicles"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Vehicles 1
          </NavLink>
        </div>
        <Filters />
        <div className="w-full">
          <div className="min-w-[800px]">
            <table className="w-full border-b border-t border-[#e7eaee]">
              <thead className="border-b border-t border-[#e7eaee]">
                <tr>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                      <input
                        type="checkbox"
                        name="termOfUse"
                        className="hidden peer"
                      />
                      <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                    </label>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]"> Vehicle Name</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Number Plate</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Latest File Start</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]"> Latest File End</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Last Retrieved</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Since Last Retrieved</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-[24px_8px]">
                    <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                      <input
                        type="checkbox"
                        name="termOfUse"
                        className="hidden peer"
                      />
                      <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                    </label>
                  </td>
                  <td className="p-[24px_8px]"> YK22WMJ</td>
                  <td className="p-[24px_8px]">YK22WMJ</td>
                  <td className="p-[24px_8px]">18 Feb 2022 05:00</td>
                  <td className="p-[24px_8px]">29 Nov 2022 17:59</td>
                  <td className="p-[24px_8px]">29 Nov 18:56</td>
                  <td className="p-[24px_8px]">152 days (Overdue)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
