import Filters from "./components/Filters";
import { NavLink } from "react-router-dom";

export default function Defects() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="mb-8">
          <h1 className="text-[26px] text-black font-bold mb-1">
            Walkaround Inspection Reports
          </h1>
          <p className="text-[#657281] text-[15px] font-medium">0 assets</p>
        </div>
        <div className="border-b border-[#e7eaee] flex items-center">
          <NavLink
            to="/walkaround-inspection-reports/defects"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Defects
          </NavLink>
          <NavLink
            to="/walkaround-inspection-reports/walkarounds"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Walkarounds
          </NavLink>
        </div>
        <Filters />
        <hr className="border-[#e7eaee]" />
        <div className="w-full my-4">
          <p className="text-[16px] text-heading">No assets found.</p>
        </div>
        <hr className="border-[#e7eaee]" />
      </div>
    </div>
  );
}
