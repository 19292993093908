import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
export default function Header({ mobileToggler, openSearchModal }) {
  return (
    <div className="flex items-center justify-around h-[50px] md:hidden border-b border-[#e7eaee]">
      <div>
        <div onClick={mobileToggler} className="cursor-pointer">
          <svg
            className="w-[24px] h-[24px] fill-[#8390a0]"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
          </svg>
        </div>
      </div>
      {/* <div className="flex">
        <button
          type="button"
          onClick={openSearchModal}
          aria-expanded="true"
          aria-controls="search"
        >
          <svg
            className="w-[24px] h-[24px] fill-[#8390a0]"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            role="presentation"
          >
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
        </button>
      </div> */}
      <div>
        <div
          data-title="Messages"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Messages"
          className="cursor-pointer"
        >
          <svg viewBox="0 0 24 24" className="w-[24px] h-[24px] fill-[#8390a0]">
            <path d="M17.93,15.3V10.57a6,6,0,0,0-4.55-6.13V2.32H10.46V4.44A6,6,0,0,0,6,10.57V15.3H3.66v3.13H20.19V15.31Z"></path>
            <path d="M9.68,19.81h4.5a0,0,0,0,1,0,0v0a1.87,1.87,0,0,1-1.87,1.87h-.76a1.87,1.87,0,0,1-1.87-1.87v0A0,0,0,0,1,9.68,19.81Z"></path>
          </svg>
        </div>
      </div>
      <div>
        <div
          data-title="Alerts"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Alerts"
          className="cursor-pointer"
        >
          <svg viewBox="0 0 24 24" className="w-[24px] h-[24px] fill-[#8390a0]">
            <path d="M2.31,2.32c0,1.94,0,.87,0,1.94V16.84H8.43L12,22l3.57-5.13h6.11V2.32Zm6.78,8.06H7.16V8.44H9.09Zm3.88,0H11V8.44H13Zm3.87,0H14.91V8.44h1.93Z"></path>
          </svg>
        </div>
      </div>
      <div>
        <Menu as="div" className="relative">
          <Menu.Button
            as="div"
            className="p-0 bg-transparent !border-none cursor-pointer"
          >
            <div className="w-[28px] h-[28px] rounded-full flex items-center justify-center bg-primary text-[12px] font-semibold relative text-white">
              M
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 bg-white rounded-[8px] mt-2 w-[170px] z-[999] origin-bottom-left min-w-[260px] shadow-[0_4px_8px_rgba(0,0,0,.15)] border-none">
              <Menu.Item as="div" className="flex">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } px-4 py-3`
                  }
                >
                  <h6 className="text-[15px] font-semibold text-black">
                    User name
                  </h6>
                  <p className="text-[14px] text-paragraph">
                    transport@icsukltd.co.uk
                  </p>
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <hr className="h-[1px] my-[5px] w-full border-none bg-[#e7eaee]" />
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } px-4 py-2`
                  }
                >
                  <p className="text-[14px] text-heading flex items-center gap-2">
                    <svg
                      className="w-[18px] h-[18px] fill-[#8390A0]"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
                    </svg>
                    Profile
                  </p>
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <hr className="h-[1px] my-[5px] w-full border-none bg-[#e7eaee]" />
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } px-4 py-2`
                  }
                >
                  <p className="text-[14px] text-heading flex items-center gap-2">
                    <svg
                      viewBox="0 0 24 24"
                      className="w-[18px] h-[18px] fill-[#8390A0]"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.3534 0H1V6.80322H4.52949V22.2574L4.53153 22.2561V24L5.80607 22.7647L7.0806 24L8.35514 22.7647L9.62968 24L10.9042 22.7647L12.1788 24L13.4533 22.7647L14.7278 24L16.0024 22.7647L17.2769 24L18.5514 22.7647L19.826 24V20.0004H19.8239V6.80322H23.3534V0ZM19.8239 3.52949V5.83133H21.0004V1.94378H3.35299V5.83133H4.52949V3.52949H12.1767H16.0003H19.8239ZM12.6669 7.05897H11.7484V8.43907C11.1516 8.49132 10.5885 8.66424 10.1631 8.98835C9.70207 9.33958 9.41475 9.85955 9.41475 10.5612C9.41475 11.1125 9.57905 11.5626 9.92273 11.8955C10.2551 12.2174 10.7012 12.458 11.246 12.6212L11.7745 12.7769V15.1775C11.4605 15.1178 11.1795 15.0077 10.9627 14.8295C10.7132 14.6242 10.5361 14.318 10.5024 13.8597L10.4935 13.7394H9.23548L9.24558 13.8772C9.29954 14.6133 9.56455 15.1887 10.0548 15.5922C10.5085 15.9656 11.0754 16.188 11.7484 16.2641V17.6474H12.6669V16.2679C13.2879 16.2173 13.88 16.0522 14.3287 15.7219C14.8147 15.3642 15.118 14.8233 15.118 14.0661C15.118 13.4968 14.9546 13.0386 14.6089 12.7111C14.277 12.3966 13.8317 12.1562 13.2869 11.9931L12.6278 11.7978V9.54611C12.8653 9.60218 13.0743 9.70733 13.2602 9.86561C13.4713 10.0452 13.6082 10.3502 13.6525 10.8098L13.6638 10.9275H14.9049L14.8961 10.7907C14.8522 10.109 14.62 9.55597 14.2132 9.15454C13.8308 8.77717 13.3049 8.54443 12.6669 8.4581V7.05897ZM10.6592 10.4953C10.6592 10.1924 10.7664 9.95081 10.967 9.7799C11.1463 9.62708 11.4124 9.52049 11.7745 9.48721V11.5738L11.6682 11.5445C11.3159 11.4436 11.0649 11.304 10.9024 11.1325C10.742 10.9633 10.6592 10.754 10.6592 10.4953ZM12.6278 15.22V13.0024L12.8638 13.0695C13.2165 13.1704 13.4677 13.3101 13.6303 13.4816C13.7907 13.6509 13.8735 13.8602 13.8735 14.1188C13.8735 14.4312 13.76 14.6966 13.5387 14.8886C13.3389 15.0619 13.0398 15.1853 12.6278 15.22Z"
                        fill="#8390A0"
                      ></path>
                    </svg>
                    Billing
                  </p>
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } px-4 py-2`
                  }
                >
                  <p className="text-[14px] text-heading flex items-center gap-2">
                    <svg
                      className="w-[18px] h-[18px] fill-[#8390A0]"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"></path>
                    </svg>
                    Orders
                  </p>
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } px-4 py-2`
                  }
                >
                  <p className="text-[14px] text-heading flex items-center gap-2">
                    <svg
                      className="w-[18px] h-[18px] fill-[#8390A0]"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
                    </svg>
                    Activate devices
                  </p>
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } px-4 py-2`
                  }
                >
                  <p className="text-[14px] text-heading flex items-center gap-2">
                    <svg
                      className="w-[18px] h-[18px] fill-[#8390A0]"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path
                        transform="scale(1.2, 1.2)"
                        d="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
                      ></path>
                    </svg>
                    Organization settings
                  </p>
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } px-4 py-2`
                  }
                >
                  <p className="text-[14px] text-heading flex items-center gap-2">
                    <svg
                      viewBox="0 0 24 24"
                      className="w-[18px] h-[18px] fill-[#8390A0]"
                    >
                      <path d="M5 13L10.59 13L10.59 16.5L15.09 12L10.59 7.5L10.59 11L5 11L5 13ZM14 18C17.31 18 20 15.31 20 12C20 8.69 17.31 6 14 6L14 8C16.21 8 18 9.79 18 12C18 14.21 16.21 16 14 16L14 18Z"></path>
                    </svg>
                    Sign out
                  </p>
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <hr className="h-[1px] my-[5px] w-full border-none bg-[#e7eaee]" />
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } px-4 py-2`
                  }
                >
                  <p className="text-[14px] text-heading flex items-center gap-2">
                    View My Organizations
                  </p>
                </NavLink>
              </Menu.Item>
              <Menu.Item as="div" className="flex">
                <hr className="h-[1px] my-[5px] w-full border-none bg-[#e7eaee]" />
              </Menu.Item>
              <Menu.Item as="div" className="flex items-center px-4 py-2">
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } text-[14px] text-heading hover:text-heading`
                  }
                >
                  Terms of Service
                </NavLink>
                <span className="inline-block mx-[8px] text-paragraph text-[14px]">
                  •
                </span>
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    `${
                      isPending ? "pending" : isActive ? "actives" : ""
                    } text-[14px] text-heading hover:text-heading`
                  }
                >
                  Privacy
                </NavLink>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
