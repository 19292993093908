import SettingsSidebar from "../../../../components/SettingsSidebar";
import { NavLink } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import AddressHeader from "./components/AddressHeader";

export default function InvalidAddresses() {
  return (
    <>
      <div className="flex">
        <SettingsSidebar />
        <div className="p-10 grow overflow-auto h-screen">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-[26px] text-black font-bold mb-2">
              Addresses/Geofences
            </h1>
            <button className="flex items-center justify-end gap-2 cursor-pointer bg-white !px-2 !py-2 rounded-md !border !border-[#c1c9d1]">
              <AiOutlinePlus size={14} />
              Add address
            </button>
          </div>
          <div className="border-b border-[#e7eaee] flex items-center">
            <NavLink
              to="/settings/addresses/valid-addresses"
              className={({ isActive, isPending }) =>
                `${
                  isPending
                    ? "pending"
                    : isActive
                    ? "!text-heading border-heading"
                    : "!text-[#4c5661] border-white"
                } p-[16px]  outline-0 border-b-[1px] font-bold flex items-center gap-2`
              }
            >
              Valid Addresses
              <span className="flex items-center justify-center text-[14px] w-[25px] h-[25px] rounded-[4px] bg-[#f6f7f9]">
                0
              </span>
            </NavLink>
            <NavLink
              to="/settings/addresses/invalid-addresses"
              className={({ isActive, isPending }) =>
                `${
                  isPending
                    ? "pending"
                    : isActive
                    ? "!text-heading border-heading"
                    : "!text-[#4c5661] border-white"
                } p-[16px]  outline-0 border-b-[1px] font-bold flex items-center gap-2`
              }
            >
              Invalid Addresses
              <span className="flex items-center justify-center text-[14px] w-[25px] h-[25px] rounded-[4px] bg-[#f6f7f9]">
                0
              </span>
            </NavLink>
          </div>
          <hr className="my-6 !border-[#e7eaee]" />
          <AddressHeader />
          <hr className="mt-6 mb-4 !border-[#e7eaee]" />
          <p className="text-[15px] text-black pl-4 font-medium">
            There are no addresses.
          </p>
          <hr className="my-4 !border-[#e7eaee]" />
        </div>
      </div>
    </>
  );
}
