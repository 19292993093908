import { useState } from "react";
import { NavLink } from "react-router-dom";
import SettingsSidebar from "../../../../components/SettingsSidebar";
import ActiveDrivers from "./components/ActiveDrivers";
import AddDriverPopup from "./components/AddDriverPopup";
import { AiOutlinePlus } from "react-icons/ai";

export default function Drivers() {
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <div className="flex">
        <SettingsSidebar />
        <div className="p-10 grow overflow-auto h-screen">
          <div className="flex justify-between items-center">
            <h1 className="text-[26px] text-black font-bold mb-8">Drivers</h1>
            <div className="flex items-center gap-3">
              <button
                onClick={openModal}
                className="!px-4 !py-2 text-white bg-[#0d6ede] rounded-[2px] flex items-center gap-2"
              >
                <AiOutlinePlus size={14} className="text-white" />
                Add a driver
              </button>
            </div>
          </div>
          <div className="border-b border-[#e7eaee] flex items-center">
            <NavLink
              to="/settings/activate-drivers"
              className={({ isActive, isPending }) =>
                `${
                  isPending
                    ? "pending"
                    : isActive
                    ? "!text-heading border-heading"
                    : "!text-[#4c5661] border-white"
                } p-[16px]  outline-0 border-b-[1px] font-bold`
              }
            >
              Active Drivers
            </NavLink>
            <NavLink
              to="/settings/deactivate-drivers"
              className={({ isActive, isPending }) =>
                `${
                  isPending
                    ? "pending"
                    : isActive
                    ? "!text-heading border-heading"
                    : "!text-[#4c5661] border-white"
                } p-[16px]  outline-0 border-b-[1px] font-bold`
              }
            >
              Deactivated Drivers
            </NavLink>
            <NavLink
              to="/settings/csv-uploads"
              className={({ isActive, isPending }) =>
                `${
                  isPending
                    ? "pending"
                    : isActive
                    ? "!text-heading border-heading"
                    : "!text-[#4c5661] border-white"
                } p-[16px]  outline-0 border-b-[1px] font-bold`
              }
            >
              CSV Uploads
            </NavLink>
          </div>
          <ActiveDrivers />
        </div>
      </div>
      <AddDriverPopup isOpen={isOpen} closeModal={closeModal} />
    </>
  );
}
