import { Tooltip } from "react-tooltip";
import SettingsSidebar from "../../../components/SettingsSidebar";
import { BsInfoCircleFill } from "react-icons/bs";

export default function SettingsGeneral() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold mb-8">
          Dispatch Settings
        </h1>
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-9">
            <div className="border border-[#e7eaee] rounded-[4px] p-[24px] mb-[24px]">
              <div className="flex items-center gap-3 mb-[24px]">
                <h3 className="text-heading text-[18px] font-semibold">
                  Route Tracking Window
                </h3>
                <a id="tracking">
                  <BsInfoCircleFill className="fill-paragraph" />
                </a>
                <Tooltip anchorSelect="#tracking" className="max-w-[300px]">
                  This is the time window that our route tracking system will
                  consider when checking for real-time updates to routes.
                  Customize this time window here.
                </Tooltip>
              </div>
              <p className="text-[15px] text-medium mb-[16px]">
                Expand the route tracking window beyond the scheduled start and
                end times.
              </p>
              <h5 className="text-[16px] font-medium mb-[5px]">
                Start of tracking window
              </h5>
              <div className="flex gap-3 items-center mb-[16px]">
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="1"
                />
                <p className="text-[15px] font-medium">
                  hour(s) before the route's scheduled start time
                </p>
              </div>
              <h5 className="text-[16px] font-medium mb-[5px]">
                End of tracking window
              </h5>
              <div className="flex gap-3 items-center mb-[16px]">
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="1"
                />
                <p className="text-[15px] font-medium">
                  hour(s) after the route's scheduled end time
                </p>
              </div>
              <p>
                Link to{" "}
                <a href="#" className="underline">
                  support article
                </a>
              </p>
            </div>
            <div className="border border-[#e7eaee] rounded-[4px] p-[24px] mb-[24px]">
              <h3 className="text-heading text-[18px] font-semibold mb-[24px]">
                Default Route Stop Time
              </h3>
              <p className="text-[15px] text-medium mb-[16px]">
                Set the default amount of time at a stop, which will
                automatically determine the stop's scheduled departure time.
              </p>
              <h5 className="text-[16px] font-medium mb-[5px]">
                Default stop time
              </h5>
              <div className="flex gap-3 items-center mb-[16px]">
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="0"
                />
                <p className="text-[15px] font-medium">hour(s)</p>
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="30"
                />
                <p className="text-[15px] font-medium">minute(s)</p>
              </div>
            </div>
            <div className="border border-[#e7eaee] rounded-[4px] p-[24px] mb-[24px]">
              <h3 className="text-heading text-[18px] font-semibold mb-[24px]">
                Route Stop Thresholds
              </h3>
              <p className="text-[15px] text-medium mb-[16px]">
                Configure how long a vehicle needs to be at a stop for it to
                count as an arrival and departed from a stop to count as a
                departure.
              </p>
              <h5 className="text-[16px] font-medium mb-[5px]">
                Arrival threshold
              </h5>
              <div className="flex gap-3 items-center mb-[16px]">
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="2"
                />
                <p className="text-[15px] font-medium">minute(s)</p>
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="0"
                />
                <p className="text-[15px] font-medium">second(s) at a stop</p>
              </div>
              <h5 className="text-[16px] font-medium mb-[5px]">
                Departure threshold
              </h5>
              <div className="flex gap-3 items-center mb-[16px]">
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="0"
                />
                <p className="text-[15px] font-medium">minute(s)</p>
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="30"
                />
                <p className="text-[15px] font-medium">second(s) at a stop</p>
              </div>
            </div>
            <div className="border border-[#e7eaee] rounded-[4px] p-[24px] mb-[24px]">
              <h3 className="text-heading text-[18px] font-semibold mb-[24px]">
                Route Stop Timeliness Window
              </h3>
              <p className="text-[15px] text-medium mb-[16px]">
                Define the time window before and after a stop's scheduled
                arrival time that's considered "on time."
              </p>
              <h5 className="text-[16px] font-medium mb-[5px]">
                Start of "on time" window
              </h5>
              <div className="flex gap-3 items-center mb-[16px]">
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="0"
                />
                <p className="text-[15px] font-medium">
                  minute(s) before a scheduled arrival time
                </p>
              </div>
              <h5 className="text-[16px] font-medium mb-[5px]">
                End of "on time" window
              </h5>
              <div className="flex gap-3 items-center mb-[16px]">
                <input
                  type="text"
                  pattern="^[0-9]?[0-9]?$"
                  className="w-[50px] h-[40px] border !border-[#c1c9d1] rounded-[4px] px-2"
                  value="0"
                />
                <p className="text-[15px] font-medium">
                  minute(s) after a scheduled arrival time
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="border border-[#e7eaee] rounded-[4px] p-[24px] mb-[24px]">
              <h3 className="text-heading text-[18px] font-semibold mb-[24px]">
                Looking for something else?
              </h3>
              <p className="text-[15px] text-medium mb-[16px]">
                For driver-related settings, visit{" "}
                <a href="#">Driver App Settings</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
