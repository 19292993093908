import { AiOutlinePlus } from "react-icons/ai";
import Filters from "./components/Filters";

export default function Schedules() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="flex justify-between items-center">
          <h1 className="text-[26px] text-black font-bold mb-8">
            Preventative Maintenance Schedules
          </h1>
          <button className="flex items-center justify-end gap-2 cursor-pointer bg-white !px-2 !py-2 rounded-md !border !border-[#c1c9d1]">
            <AiOutlinePlus size={14} />
            Add a schedule
          </button>
        </div>
        <Filters />
        <hr className="border-[#e7eaee]" />
        <div className="w-full my-4">
          <p className="text-[16px] text-heading">No maintenance schedules</p>
        </div>
        <hr className="border-[#e7eaee]" />
      </div>
    </div>
  );
}
