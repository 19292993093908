import { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import AssetsHeader from "./components/AssetsHeader";
import { FaMapMarkerAlt } from "react-icons/fa";

export default function Assets() {
  return (
    <div className="p-10">
      <h1 className="text-[26px] text-black font-bold mb-8">Assets</h1>
      <hr className="border-[#e7eaee]" />
      <AssetsHeader />
      <div className="w-full">
        <div className="min-w-[800px]">
          <table className="w-full border-b border-t border-[#e7eaee]">
            <thead className="border-b border-t border-[#e7eaee]">
              <tr>
                <th
                  data-title="name"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Name
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Location
                  </span>
                </th>
                <th
                  data-title="vehicleLocation"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Last Trip
                  </span>
                </th>
                <th
                  data-title="latestDriverAppVersion"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span className="text-[14px]"> Status </span>
                </th>
                <th
                  data-title="latestDeviceOSVersion"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span className="text-[14px]">Current Fuel Level </span>
                </th>
                <th
                  data-title="latestDeviceOSVersion"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span className="text-[14px]">Current Driver </span>
                </th>
                <th
                  data-title="latestDeviceOSVersion"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span className="text-[14px]"> Number Plate </span>
                </th>
                <th
                  data-title="latestDeviceOSVersion"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span className="text-[14px]">Tags </span>
                </th>
                <th
                  data-title="latestDeviceOSVersion"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span className="text-[14px]"> </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">
                    <span className="underline mb-1 inline-block">
                      EBRAHIM MOOSA
                    </span>
                    <div className="text-paragraph text-[14px]">GANGAT4052</div>
                  </div>
                </td>
                <td className="p-[24px_8px]">
                  <div className="flex items-center">
                    <div className="shrink-0 mr-2">
                      <FaMapMarkerAlt size={15} className="fill-[#8390a0]" />
                    </div>
                    <span className="text-paragraph text-[16px]">
                      Location unknown
                    </span>
                  </div>
                </td>
                <td className="p-[24px_8px]">
                  <span data-notranslate="true">Never active</span>
                </td>
                <td className="p-[24px_8px]">
                  <span className="inline-block px-2 py-1 bg-inverted">
                    OFF
                  </span>
                </td>
                <td className="p-[24px_8px]">-</td>
                <td className="p-[24px_8px]"></td>
                <td className="p-[24px_8px]"></td>
                <td className="p-[24px_8px]"></td>
                <td className="p-[24px_8px]">
                  <Menu as="div" className="relative">
                    <Menu.Button
                      as="div"
                      className="flex items-center justify-end gap-2 cursor-pointer bg-white !px-2 !py-2"
                    >
                      <BiDotsHorizontalRounded
                        size={22}
                        className="text-paragraph"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-[170px] z-[999] origin-top-right rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none">
                        <Menu.Item
                          as="div"
                          className="p-[8px_16px] text-[14px] hover:bg-gray-100 cursor-pointer"
                        >
                          Settings
                        </Menu.Item>
                        <Menu.Item
                          as="div"
                          className="p-[8px_16px] text-[14px] hover:bg-gray-100 cursor-pointer"
                        >
                          Deactivate Gateway
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
