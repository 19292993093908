import SettingsSidebar from "../../../../components/SettingsSidebar";
import FuelEnergyTabs from "../components/FuelEnergyTabs";
import EmptyCart from "../../../../assets/images/empty_card.png";

export default function EcoDriving() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold mb-8">
          Fuel & Energy Settings
        </h1>
        <FuelEnergyTabs />
        <div className="flex justify-between items-center mt-[15vh]">
          <div className="text-center w-full">
            <img
              src={EmptyCart}
              alt="Empty"
              className="w-[194px] h-[194px] mx-auto"
            />
            <h2 className="text-[28px] text-heading font-bold mb-[8px]">
              Link your fuel cards.
            </h2>
            <p className="text-[15px] text-paragraph font-medium max-w-[480px] mx-auto">
              Connect your fuel cards to automatically import fuel purchases
              into Samsara.
            </p>
            <div className="flex items-center justify-center gap-3 mt-4">
              <button className="!px-4 !py-2 text-heading text-[14px] bg-white border border-[#c1c9d1] rounded-[2px] flex items-center gap-2">
                Learn More
              </button>
              <button className="!px-4 !py-2 text-white text-[14px] bg-primary rounded-[2px] flex items-center gap-2">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
