import React, { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import UserHeader from "../UserHeader";

export default function Users() {
  return (
    <>
      <UserHeader />
      <div className="w-full">
        <div className="min-w-[800px]">
          <table className="w-full border-b border-t border-[#e7eaee]">
            <thead className="border-b border-t border-[#e7eaee]">
              <tr>
                <th
                  data-title="name"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    User
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Roles
                  </span>
                </th>
                <th
                  data-title="vehicleLocation"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">
                    <div className="flex items-center gap-3">
                      <img
                        src="https://ionicframework.com/docs/img/demos/avatar.svg"
                        className="w-[40px] rounded-md shrink-0"
                      />
                      <div>
                        <h6 className="text-black font-semibold">
                          Muhammad Bilal
                        </h6>
                        <p className="text-paragraph">
                          transport@icsukltd.co.uk
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="p-[24px_8px]">
                  <p>
                    Full Admin for
                    <span
                      data-notranslate="true"
                      className="rounded-md bg-[#f2f7ff] text-[#0d6ede] text-[14px] p-[3px_5px] font-medium inline-block !border !border-[#9ac9fe] ml-2"
                    >
                      Entire Organization
                    </span>
                  </p>
                </td>
                <td className="p-[24px_8px]">
                  <Menu as="div" className="relative">
                    <Menu.Button
                      as="div"
                      className="flex items-center justify-end gap-2 cursor-pointer hover:bg-inverted p-2 rounded-md"
                    >
                      <BiDotsHorizontalRounded
                        size={22}
                        className="text-paragraph"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-[170px] origin-top-right rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none">
                        <Menu.Item
                          as="div"
                          className="p-[10px_16px] hover:bg-gray-100 cursor-pointer"
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          as="div"
                          className="p-[10px_16px] hover:bg-gray-100 cursor-pointer"
                        >
                          Delete
                        </Menu.Item>{" "}
                        <Menu.Item
                          as="div"
                          className="p-[10px_16px] hover:bg-gray-100 cursor-pointer"
                        >
                          Log in as User
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
