import SettingsSidebar from "../../../../components/SettingsSidebar";
import FuelTypeHeader from "./components/FuelTypeHeader";
import FuelEnergyTabs from "../components/FuelEnergyTabs";
import FuelTypeTab from "./components/FuelTypeTab";
export default function VehicleFuelType() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold mb-8">
          Fuel & Energy Settings
        </h1>
        <FuelEnergyTabs />
        <FuelTypeHeader />
        <FuelTypeTab />
      </div>
    </div>
  );
}
