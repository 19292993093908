import SettingsSidebar from "../../../components/SettingsSidebar";
import { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { BsInfoCircleFill } from "react-icons/bs";
import Filters from "./components/Filters";

export default function ActivityLog() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <div className="bg-[#f2f7ff] p-[8px_12px] rounded-[3px]">
          <div className="flex items-center gap-2">
            <BsInfoCircleFill size={15} className="text-[#0d6ede]" />
            <p className=" text-[16px] font-medium">
              We’ve recently updated our Activity Log to give you more granular
              information about events that have taken place in your
              organization. You can access the old version
            </p>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <h1 className="text-[26px] text-black font-bold mb-8">
            Activity Log
          </h1>
          <Menu as="div" className="relative">
            <Menu.Button
              as="div"
              className="flex items-center justify-end gap-2 cursor-pointer bg-white !px-2 !py-2 rounded-md !border !border-[#c1c9d1]"
            >
              <BiDotsHorizontalRounded size={22} className="text-paragraph" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 min-w-[170px] z-[999] origin-top-right rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none">
                <Menu.Item
                  as="div"
                  className="p-[8px_16px] text-[14px] hover:bg-gray-100 cursor-pointer whitespace-nowrap"
                >
                  Export
                </Menu.Item>
                <Menu.Item
                  as="div"
                  className="p-[8px_16px] text-[14px] hover:bg-gray-100 cursor-pointer whitespace-nowrap"
                >
                  Schedule Emailed Report
                </Menu.Item>
                <Menu.Item
                  as="div"
                  className="p-[8px_16px] text-[14px] hover:bg-gray-100 cursor-pointer whitespace-nowrap"
                >
                  Print
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <hr className="mt-3 !border-[#e7eaee]" />
        <Filters />
        <div className="w-full">
          <div className="min-w-[800px]">
            <table className="w-full border-b border-t border-[#e7eaee]">
              <thead className="border-b border-t border-[#e7eaee]">
                <tr>
                  <th
                    data-title="name"
                    className="p-[32px_8px_16px_8px] whitespace-nowrap"
                  >
                    <span data-isolate="true" className="text-[14px]">
                      transport@icsukltd.co.uk
                    </span>
                  </th>
                  <th
                    data-title="vehicleName"
                    className="p-[32px_8px_16px_8px] whitespace-nowrap"
                  >
                    <span data-isolate="true" className="text-[14px]">
                      Signed In
                    </span>
                  </th>
                  <th
                    data-title="vehicleName"
                    className="p-[32px_8px_16px_8px] whitespace-nowrap"
                  >
                    <span data-isolate="true" className="text-[14px]">
                      12 Apr 2023 21:31:29
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-[24px_8px]">
                    <div data-notranslate="true">EBRAHIM MOOSA</div>
                  </td>
                  <td className="p-[24px_8px]">
                    <div data-notranslate="true">GANGAT4052</div>
                  </td>
                  <td className="p-[24px_8px]">
                    <div data-notranslate="true">DB101271671617</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
