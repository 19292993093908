import React, { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import DeactivateHeader from "../DeactivateHeader";

export default function DeactivatedDrivers() {
  return (
    <>
      <DeactivateHeader />
      <div className="w-full">
        <div className="min-w-[800px]">
          <table className="w-full border-b border-t border-[#e7eaee]">
            <thead className="border-b border-t border-[#e7eaee]">
              <tr>
                <th
                  data-title="name"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Name
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Username
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Tachograph Card
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Tags
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Peer Group
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Phone
                  </span>
                </th>
                <th
                  data-title="vehicleLocation"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">EBRAHIM MOOSA</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">GANGAT4052</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">DB101271671617</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true"></div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true"></div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">000000000000</div>
                </td>
                <td className="p-[24px_8px]">
                  <Menu as="div" className="relative">
                    <Menu.Button
                      as="div"
                      className="flex items-center justify-end gap-2 cursor-pointer hover:bg-inverted p-2 rounded-md"
                    >
                      <BiDotsHorizontalRounded
                        size={22}
                        className="text-paragraph"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 mt-2 w-[170px] origin-top-right rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none">
                        <Menu.Item
                          as="div"
                          className="p-[10px_16px] hover:bg-gray-100 cursor-pointer"
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          as="div"
                          className="p-[10px_16px] hover:bg-gray-100 cursor-pointer"
                        >
                          Deactivate
                        </Menu.Item>{" "}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
