import { AiOutlinePlus } from "react-icons/ai";
import Filters from "./components/Filters";

export default function Upcoming() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="flex justify-between items-center">
          <h1 className="text-[26px] text-black font-bold mb-8">
            Upcoming Preventative Maintenance Items
          </h1>
        </div>
        <hr className="border-[#e7eaee]" />
        <Filters />
        <hr className="border-[#e7eaee]" />
        <div className="w-full my-4">
          <p className="text-[16px] text-heading">
            No upcoming preventative maintenance items.
          </p>
        </div>
        <hr className="border-[#e7eaee]" />
      </div>
    </div>
  );
}
