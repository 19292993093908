import SettingsSidebar from "../../../../components/SettingsSidebar";
import { AiOutlinePlus } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import FuelEnergyTabs from "../components/FuelEnergyTabs";

export default function FuelCards() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold mb-8">
          Fuel & Energy Settings
        </h1>
        <FuelEnergyTabs />
        <div className="flex justify-between items-center mt-12">
          <h1 className="text-[20px] text-black font-bold">Score Profiles</h1>
          <div className="flex items-center gap-3">
            <button className="!px-4 !py-2 text-white bg-primary rounded-[2px] flex items-center gap-2">
              <AiOutlinePlus size={14} className="text-white" />
              Create profile
            </button>
            <button className="!px-4 !py-2 text-heading text-[14px] bg-white border border-[#c1c9d1] rounded-[2px] flex items-center gap-2">
              Assign vehicles
            </button>
          </div>
        </div>
        <div className="border border-[#e7eaee] rounded-[4px] p-[24px] mb-[24px] mt-[24px]">
          <div className="flex items-center gap-8 justify-between">
            <div className="flex items-center gap-8">
              <svg width="75" height="75">
                <g transform="translate(37.5,37.5)">
                  <path
                    d="M2.296212748401287e-15,-37.5A37.5,37.5 0 0,1 29.31868059255112,-23.380867569702502L19.936702802934764,-15.898989947397702A25.5,25.5 0 0,0 1.5614246689128753e-15,-25.5Z"
                    fill="#0fb6c2"
                  ></path>
                  <path
                    d="M29.31868059255112,-23.380867569702502A37.5,37.5 0 0,1 36.55979670681838,8.344535023361807L24.8606617606365,5.674283815886028A25.5,25.5 0 0,0 19.936702802934764,-15.898989947397702Z"
                    fill="#0d6ede"
                  ></path>
                  <path
                    d="M36.55979670681838,8.344535023361807A37.5,37.5 0 0,1 16.2706402169084,33.78633254634073L11.064035347497713,22.974706131511695A25.5,25.5 0 0,0 24.8606617606365,5.674283815886028Z"
                    fill="#ABCDFF"
                  ></path>
                  <path
                    d="M16.2706402169084,33.78633254634073A37.5,37.5 0 0,1 -16.270640216908458,33.7863325463407L-11.06403534749775,22.97470613151168A25.5,25.5 0 0,0 11.064035347497713,22.974706131511695Z"
                    fill="#5426d2"
                  ></path>
                  <path
                    d="M-16.270640216908458,33.7863325463407A37.5,37.5 0 0,1 -36.55979670681839,8.344535023361761L-24.860661760636503,5.674283815885998A25.5,25.5 0 0,0 -11.06403534749775,22.97470613151168Z"
                    fill="#0db010"
                  ></path>
                  <path
                    d="M-36.55979670681839,8.344535023361761A37.5,37.5 0 0,1 -29.3186805925511,-23.38086756970253L-19.93670280293475,-15.898989947397721A25.5,25.5 0 0,0 -24.860661760636503,5.674283815885998Z"
                    fill="#ee6917"
                  ></path>
                  <path
                    d="M-29.3186805925511,-23.38086756970253A37.5,37.5 0 0,1 2.641805249355083e-14,-37.5L1.7964275695614568e-14,-25.5A25.5,25.5 0 0,0 -19.93670280293475,-15.898989947397721Z"
                    fill="#B7A6FE"
                  ></path>
                </g>
              </svg>
              <a href="#">Default organisation profile</a>
            </div>
            <div className="flex items-center gap-8">
              <a href="#">4 vehicles</a>
              <span>
                <MdEdit size={20} className="fill-paragraph" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
