export default function FuelTypeTab() {
  return (
    <div className="w-full">
      <div className="min-w-[800px]">
        <table className="w-full border-b border-t border-[#e7eaee]">
          <thead className="border-b border-t border-[#e7eaee]">
            <tr>
              <th
                data-title="name"
                className="p-[32px_8px_16px_8px] whitespace-nowrap"
              >
                <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                  <input
                    type="checkbox"
                    name="termOfUse"
                    className="hidden peer"
                  />
                  <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                </label>
              </th>
              <th
                data-title="name"
                className="p-[32px_8px_16px_8px] whitespace-nowrap"
              >
                <span
                  data-isolate="true"
                  className="text-[13px] font-bold inline-block"
                >
                  Vehicle
                </span>
              </th>
              <th
                data-title="vehicleName"
                className="p-[32px_8px_16px_8px] whitespace-nowrap"
              >
                <span
                  data-isolate="true"
                  className="text-[13px] font-bold inline-block"
                >
                  Year
                </span>
              </th>
              <th
                data-title="vehicleName"
                className="p-[32px_8px_16px_8px] whitespace-nowrap"
              >
                <span
                  data-isolate="true"
                  className="text-[13px] font-bold inline-block"
                >
                  Make
                </span>
              </th>
              <th
                data-title="vehicleName"
                className="p-[32px_8px_16px_8px] whitespace-nowrap"
              >
                <span
                  data-isolate="true"
                  className="text-[13px] font-bold inline-block"
                >
                  Model
                </span>
              </th>
              <th
                data-title="vehicleName"
                className="p-[32px_8px_16px_8px] whitespace-nowrap"
              >
                <span
                  data-isolate="true"
                  className="text-[13px] font-bold inline-block"
                >
                  IFTA Fuel Type
                </span>
              </th>
              <th
                data-title="vehicleLocation"
                className="p-[32px_8px_16px_8px] whitespace-nowrap"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-[24px_8px]">
                <div data-notranslate="true">
                  <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                    <input
                      type="checkbox"
                      name="termOfUse"
                      className="hidden peer"
                    />
                    <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                  </label>
                </div>
              </td>
              <td className="p-[24px_8px]">
                <div data-notranslate="true">GA6D-PMG-UJJ</div>
              </td>
              <td className="p-[24px_8px]">
                <div data-notranslate="true">0</div>
              </td>
              <td className="p-[24px_8px]">
                <div data-notranslate="true"></div>
              </td>
              <td className="p-[24px_8px]"></td>
              <td className="p-[24px_8px]">
                <div data-notranslate="true"> Unspecified</div>
              </td>
              <td className="p-[24px_8px]">
                <button className="border bg-white text-heading text-[14px] px-3 h-10 rounded-[4px]">
                  Edit Fuel Type
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
