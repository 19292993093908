import SettingsSidebar from "../../../../components/SettingsSidebar";
import Summary from "./components/Summary";
import { NavLink } from "react-router-dom";

export default function Tags() {
  return (
    <>
      <div className="flex">
        <SettingsSidebar />
        <div className="p-10 grow overflow-auto h-screen">
          <div className="">
            <h1 className="text-[26px] text-black font-bold mb-2">Billing</h1>
            <p className="text-paragraph text-[15px] font-medium">
              Invoice data last updated as of 00:03
            </p>
          </div>
          <div className="border-b border-[#e7eaee] flex items-center">
            <NavLink
              to="/settings/billing/summary"
              className={({ isActive, isPending }) =>
                `${
                  isPending
                    ? "pending"
                    : isActive
                    ? "!text-heading border-heading"
                    : "!text-[#4c5661] border-white"
                } p-[16px]  outline-0 border-b-[1px] font-bold`
              }
            >
              Summary
            </NavLink>
            <NavLink
              to="/settings/billing/invoices"
              className={({ isActive, isPending }) =>
                `${
                  isPending
                    ? "pending"
                    : isActive
                    ? "!text-heading border-heading"
                    : "!text-[#4c5661] border-white"
                } p-[16px]  outline-0 border-b-[1px] font-bold`
              }
            >
              Invoices
            </NavLink>
          </div>
          <Summary />
        </div>
      </div>
    </>
  );
}
