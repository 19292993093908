import DriverHeader from "../DevicesHeader";
export default function DeactivatedDrivers() {
  return (
    <>
      <DriverHeader />
      <hr className="mb-3 !border-[#e7eaee]" />
      <div className="w-full">
        <h3>There is no data to display.</h3>
      </div>
      <hr className="mt-3 !border-[#e7eaee]" />
    </>
  );
}
