export default function TireCondition() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="max-w-[450px] text-center mt-[28vh] mx-auto">
          <h1 className="text-[26px] text-black font-bold mb-2">
            No tire pressure sensors connected
          </h1>
          <p className="text-paragraph text-[16px] font-medium">
            The Live Tire Condition Report surfaces the tire status of all
            assets connected to 3rd party tire pressure sensors.
          </p>
          <button className="!px-4 !py-2 text-white bg-[#0d6ede] rounded-[2px] flex items-center gap-2 mx-auto mt-4 text-[14px]">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}
