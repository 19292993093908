import React from "react";
import { HiOutlineSearch } from "react-icons/hi";

export default function Filters() {
  return (
    <div className="flex items-center gap-2 divide-x divide-[#e7eaee] py-[10px]">
      <h5 className="text-[20px] text-black font-bold">Schedules</h5>

      <div className="ml-auto border-none flex items-center">
        <div className="relative">
          <input
            className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
            placeholder="Search schedules"
          />
          <HiOutlineSearch
            size={16}
            className="absolute left-[7px] top-[14px] text-paragraph"
          />
        </div>
      </div>
    </div>
  );
}
