import Filters from "./components/Filters";
import { NavLink } from "react-router-dom";

export default function Walkarounds() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="mb-8">
          <h1 className="text-[26px] text-black font-bold mb-1">
            Walkaround Inspection Reports
          </h1>
          <p className="text-[#657281] text-[15px] font-medium">
            0 inspection reports from 2 May - 2 May
          </p>
        </div>
        <div className="border-b border-[#e7eaee] flex items-center mb-4">
          <NavLink
            to="/walkaround-inspection-reports/defects"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Defects
          </NavLink>
          <NavLink
            to="/walkaround-inspection-reports/walkarounds"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Walkarounds
          </NavLink>
        </div>
        <div className="flex items-center gap-5">
          <div className="flex items-center gap-2 bg-[#f6f7f9] rounded-[24px] px-4 py-2">
            <h6 className="text-[14px] text-black font-semibold">All</h6>
            <p className="text-[14px] w-[25px] h-[25px] rounded-full bg-white flex items-center justify-center">
              0
            </p>
          </div>
          <div className="flex items-center gap-2 rounded-[24px] px-4 py-2">
            <h6 className="text-[14px] text-black/70 font-semibold">Safe</h6>
            <p className="text-[14px] text-black/70 w-[25px] h-[25px] rounded-full bg-white flex items-center justify-center">
              0
            </p>
          </div>
          <div className="flex items-center gap-2 rounded-[24px] px-4 py-2">
            <h6 className="text-[14px] text-black/70 font-semibold">Unsafe</h6>
            <p className="text-[14px] text-black/70 w-[25px] h-[25px] rounded-full bg-white flex items-center justify-center">
              0
            </p>
          </div>
          <div className="flex items-center gap-2 rounded-[24px] px-4 py-2">
            <h6 className="text-[14px] text-black/70 font-semibold">
              Resolved
            </h6>
            <p className="text-[14px] text-black/70 w-[25px] h-[25px] rounded-full bg-white flex items-center justify-center">
              0
            </p>
          </div>
        </div>
        <hr className="border-[#e7eaee] mt-4" />
        <Filters />
        <hr className="border-[#e7eaee]" />
        <div className="w-full my-4">
          <p className="text-[16px] text-heading">
            No walkaround inspection reports found.
          </p>
        </div>
        <hr className="border-[#e7eaee]" />
      </div>
    </div>
  );
}
