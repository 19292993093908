import SettingsSidebar from "../../../../components/SettingsSidebar";
import FuelEnergyTabs from "../components/FuelEnergyTabs";
import { MdEdit } from "react-icons/md";
export default function VehicleFuelType() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold mb-8">
          Fuel & Energy Settings
        </h1>
        <FuelEnergyTabs />
        <div>
          <div class="border border-[#e7eaee] rounded-[4px] p-[24px] mb-[24px] mt-[24px]">
            <div class="flex items-center gap-8 justify-between mb-4">
              <h4 className="text-[20px] font-medium text-heading">
                Custom Fuel Cost
              </h4>
              <span className="flex items-center text-[15px] gap-3">
                <MdEdit size={20} className="fill-paragraph" />
                Edit
              </span>
            </div>
            <p className="text-[16px] text-paragraph font-medium mb-3">
              Updating fuel cost will not affect historical estimates. However,
              the estimates going forward will use this fuel cost.
            </p>
            <h5 className="text-[15px] font-medium text-heading mb-3">
              Set Custom Fuel Cost Per Gallon (Imp)
            </h5>
            <h5 className="text-[15px] font-medium text-heading mb-4">£0.00</h5>
            <h5 className="text-[15px] font-medium text-heading mb-1">
              Custom Fuel Cost History
            </h5>
            <hr className="border-[#e7eaee]" />
            <h5 className="text-[15px] font-medium text-heading my-4 pl-4">
              No rows to display
            </h5>
            <hr className="border-[#e7eaee]" />
          </div>
        </div>
      </div>
    </div>
  );
}
