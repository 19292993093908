import React, { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { AiFillCaretDown } from "react-icons/ai";
import { HiOutlineSearch } from "react-icons/hi";
import { BiCalendar } from "react-icons/bi";
import { BsFillTagFill } from "react-icons/bs";

export default function Filters() {
  return (
    <div className="flex items-center gap-2 divide-x divide-[#e7eaee] py-[10px]">
      <div className="flex items-center gap-3 cursor-pointer">
        <FiChevronLeft size={20} className="text-paragraph" />
        <div className="flex items-center gap-2 relative ">
          <BiCalendar size={20} className="text-paragraph" />
          <sapn>Today</sapn>
          <input
            type="datetime-local"
            className="absolute opacity-0 w-[unset] max-w-[unset] inset-0"
          />
        </div>
        <FiChevronRight size={20} className="text-paragraph" />
      </div>
      <Menu as="div" className="relative">
        <Menu.Button
          as="div"
          className="flex items-center gap-2 cursor-pointer hover:bg-inverted p-2 rounded-md"
        >
          <BsFillTagFill size={18} className="text-paragraph" />
          <span>Tags</span>
          <AiFillCaretDown size={14} className="text-paragraph" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[300px] origin-top-left rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none border border-[#e7eaee]">
            <div className="p-[16px] border-b">
              <div className="relative">
                <input
                  className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
                  placeholder="Search Options"
                />
                <HiOutlineSearch
                  size={16}
                  className="absolute left-[7px] top-[14px] text-paragraph"
                />
              </div>
            </div>
            <div className="p-[16px]">
              <div className="relative">
                <p className="text-[14px] font-medium text-heading">
                  No tags in this organization.{" "}
                  <a href="#" className="text-[#0d6ede]">
                    Manage
                  </a>
                </p>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <Menu as="div" className="relative !pl-2">
        <Menu.Button
          as="div"
          className="flex items-center gap-2 cursor-pointer hover:bg-inverted p-2 rounded-md"
        >
          <span>Defects</span>
          <AiFillCaretDown size={14} className="text-paragraph" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[300px] origin-top-left rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none border border-[#e7eaee]">
            <div className="p-[14px] border-b">
              <h6 className="text-[16px] text-black font-semibold">Defects</h6>
              <div className="mt-3 flex flex-col gap-3">
                <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                  <input
                    type="checkbox"
                    name="termOfUse"
                    className="hidden peer"
                  />
                  <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                  Show reports with defects
                </label>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <Menu as="div" className="relative !pl-2">
        <Menu.Button
          as="div"
          className="flex items-center gap-2 cursor-pointer hover:bg-inverted p-2 rounded-md"
        >
          <span>Type</span>
          <AiFillCaretDown size={14} className="text-paragraph" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[300px] origin-top-left rounded-[8px] bg-white shadow-[0px_4px_8px_#00000026] focus:outline-none border border-[#e7eaee]">
            <div className="p-[14px] border-b">
              <h6 className="text-[16px] text-black font-semibold">Type</h6>
              <div className="mt-3 flex flex-col gap-3">
                <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                  <input
                    type="checkbox"
                    name="termOfUse"
                    className="hidden peer"
                  />
                  <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                  Unspecified
                </label>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <div className="ml-auto border-none flex items-center">
        <div className="relative">
          <input
            className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
            placeholder="Search inspection reports"
          />
          <HiOutlineSearch
            size={16}
            className="absolute left-[7px] top-[14px] text-paragraph"
          />
        </div>
      </div>
    </div>
  );
}
