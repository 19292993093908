import { NavLink } from "react-router-dom";

export default function FuelEnergyTabs() {
  return (
    <div className="border-b border-[#e7eaee] flex items-center">
      <NavLink
        to="/settings/fuel-energy/eco-driving"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Eco-Driving
      </NavLink>
      <NavLink
        to="/settings/fuel-energy/fuel-cards"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Fuel Cards
      </NavLink>
      <NavLink
        to="/settings/fuel-energy/vehicle-fuel-types"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Vehicle Fuel Types
      </NavLink>
      <NavLink
        to="/settings/fuel-energy/fuel-cost"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Fuel Cost
      </NavLink>
      <NavLink
        to="/settings/fuel-energy/fuel-purchase"
        className={({ isActive, isPending }) =>
          `${
            isPending
              ? "pending"
              : isActive
              ? "!text-heading border-heading"
              : "!text-[#4c5661] border-white"
          } p-[16px]  outline-0 border-b-[1px] font-bold`
        }
      >
        Fuel Purchases
      </NavLink>
    </div>
  );
}
