import React, { useState } from "react";
import OverviewHeader from "./components/OverviewHeader";
import { HiOutlineSearch } from "react-icons/hi";
import { MdChevronLeft } from "react-icons/md";
import { RiMapPin2Fill } from "react-icons/ri";
import { FaUserAlt, FaVideo } from "react-icons/fa";
export default function OverviewSidebar() {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`${
        show && "w-[25px]"
      } w-[450px] border-r border-[#e7eaee] h-screen relative`}
    >
      <div
        onClick={() => setShow(!show)}
        className="w-[32px] h-[32px] group absolute top-[22px] cursor-pointer -right-[16px] flex items-center hover:bg-[#17191c] hover:border-[#17191c] justify-center border rounded-full bg-white"
      >
        <MdChevronLeft
          size={24}
          className={`${
            show && "rotate-180"
          } text-paragraph group-hover:text-white`}
        />
      </div>
      <div className={`${show && "hidden"}`}>
        <div className="p-[16px_24px]">
          <div className="relative">
            <input
              className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
              placeholder="Search assets"
            />
            <HiOutlineSearch
              size={16}
              className="absolute left-[7px] top-[14px] text-paragraph"
            />
          </div>
          <OverviewHeader />
        </div>
        <hr className="border-[#e7eaee]" />
        <div className="p-[24px] hover:bg-inverted cursor-pointer">
          <div className="flex items-center justify-between">
            <h5 className="text-[14px] font-semibold text-black">
              GA6D-PMG-UJJ
            </h5>
            <button className="bg-[#f0f2f4] p-[4px_8px] font-semibold rounded-full uppercase text-[12px] text-heading">
              Not installed
            </button>
          </div>
          <div className="flex items-center gap-2">
            <RiMapPin2Fill size={14} className="text-paragraph" />
            <p className="text-paragraph text-[14px]">Location unknown</p>
          </div>
        </div>
        <hr className="border-[#e7eaee]" />
        <div className="p-[24px] hover:bg-inverted cursor-pointer">
          <div className="flex items-center justify-between">
            <h5 className="text-[14px] font-semibold text-black">
              Tacho Reader
            </h5>
          </div>
          <div className="flex items-center gap-2">
            <RiMapPin2Fill size={14} className="text-paragraph" />
            <p className="text-paragraph text-[14px]">Location unknown</p>
          </div>
        </div>
        <hr className="border-[#e7eaee]" />
        <div className="p-[24px] hover:bg-inverted cursor-pointer">
          <div className="flex items-center gap-2">
            <h5 className="text-[14px] font-semibold text-black">YK22WMJ</h5>
            <FaVideo size={14} className="text-paragraph" />
          </div>
          <div className="flex items-center gap-2 mt-1">
            <RiMapPin2Fill size={14} className="text-paragraph" />
            <p className="text-paragraph text-[14px]">
              Neville Road, BRADFORD BD4 8TU, GB
            </p>
          </div>
          <div className="flex items-center gap-2 mt-1">
            <FaUserAlt size={12} className="text-paragraph" />
            <p className="text-paragraph text-[13px]">EBRAHIM MOOSA</p>
          </div>
        </div>
        <hr className="border-[#e7eaee]" />
      </div>
    </div>
  );
}
