import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
export default function SearchModal({ isOpen, dismissSearchModal }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog onClose={dismissSearchModal} className="relative z-[9999]">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-start justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto shadow-[0_2px_16px_#657281] !max-w-[800px] mt-[20vh] bg-white !rounded-[4px] w-full">
              <input
                className="px-3 py-2 bg-transparent w-full outline-0 text-[#000000] placeholder:!font-medium border-b !font-medium"
                type="text"
                placeholder="Jump to..."
              />
              <p className="text-[14px] text-paragraph p-[4px_8px]">
                Search for a driver, vehicle, asset, or report.
                <span className="">Help</span>
                <a
                  href="#"
                  data-bs-toggle="tooltip"
                  className="font-bold"
                  data-bs-html="true"
                  data-bs-title="<div className='text-left'><div>hos for [driver]</div><div>safety report for [driver]</div><div>message [driver]</div><div>routes for [driver]</div><div>help [topic]</div></div>"
                >
                  Help
                </a>
              </p>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
