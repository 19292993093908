import { Fragment, useState } from "react";
import { Combobox, Transition, Listbox } from "@headlessui/react";
import { AiFillCaretDown } from "react-icons/ai";

const people = [];
const distances = [100, 200, 300];

export default function ProximitySidebar() {
  const [selected, setSelected] = useState(people[0]);
  const [distance, setDistance] = useState(distances[0]);
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  return (
    <div className={`w-[500px] border-r border-[#e7eaee] h-screen relative`}>
      <div className="p-[16px_24px]">
        <h2 className="text-[26px] mb-1 text-heading font-bold">
          Proximity Search
        </h2>
        <p className="text-[15px] text-paragraph font-medium mb-8">
          Search for an address to see which assets were previously nearby.
        </p>
        <div className="mb-4">
          <label className="text-[15px] mb-1 text-heading font-medium">
            Search for assets near
          </label>
          <Combobox value={selected} onChange={setSelected}>
            <div className="relative mt-1">
              <div className="relative w-full cursor-pointer text-left focus:outline-none sm:text-sm">
                <Combobox.Button className="w-full">
                  <Combobox.Input
                    className="w-full border-[#c1c9d1] border rounded-[3px] py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 outline-0"
                    displayValue={(person) => person.name}
                    placeholder="Search for an address..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <AiFillCaretDown
                      size={14}
                      className="text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-[0px_4px_8px_#00000026] ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredPeople.length === 0 || query !== "" ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      No addresses found.
                    </div>
                  ) : (
                    filteredPeople.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 px-4 ${
                            active ? "bg-gray-100 text-black" : "text-gray-900"
                          }`
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {person.name}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
        <div className="mb-4">
          <label className="text-[15px] mb-1 text-heading font-medium">
            Distance (meters)
          </label>
          <Listbox value={distance} onChange={setDistance}>
            <div className="relative mt-1">
              <Listbox.Button className="relative border-[#c1c9d1] border rounded-[3px] w-full cursor-pointer py-2 pl-3 pr-10 text-left sm:text-sm">
                <span className="block truncate">{distance}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <AiFillCaretDown
                    size={14}
                    className="text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-[0px_4px_8px_#00000026] ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {distances.map((person, personIdx) => (
                    <Listbox.Option
                      key={personIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 px-4 ${
                          active ? "bg-gray-100 text-black" : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ distance }) => (
                        <>
                          <span
                            className={`block truncate ${
                              distance ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div className="mb-4">
          <label className="text-[15px] mb-1 text-heading font-medium">
            Start Time
          </label>
          <input
            type="date"
            className="w-full border-[#c1c9d1] border rounded-[3px] py-[6px] pl-3 text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="text-[15px] mb-1 text-heading font-medium">
            End Time
          </label>
          <input
            type="date"
            className="w-full border-[#c1c9d1] border rounded-[3px] py-[6px] pl-3 text-sm"
          />
        </div>
      </div>
      <hr className="border-[#e7eaee]" />
    </div>
  );
}
