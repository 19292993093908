import { NavLink } from "react-router-dom";
export default function SettingsSidebar() {
  return (
    <div className="overflow-y-auto overflow-x-hidden h-screen shrink-0 min-w-[150px] border-r border-[#e7eaee]">
      <div className="p-[16px_16px_16px_24px]">
        <h2 className="my-[4px] text-[20px] text-black font-semibold mb-[32px]">
          Settings
        </h2>
        <h6 className="text-paragraph font-bold text-[15px]">ORGANIZATION</h6>
        <ul>
          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/general"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              General
            </NavLink>
          </li>
          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/users"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Users & Roles
            </NavLink>
          </li>
          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/activate-drivers"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Drivers
            </NavLink>
          </li>
          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/tags"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Tags
            </NavLink>
          </li>
          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/activity-log"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Activity Log
            </NavLink>
          </li>
          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/billing/summary"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Billing
            </NavLink>
          </li>
        </ul>
        <div>
          <hr className="my-[16px] border-t !border-[#e7eaee]" />
        </div>
        <h6 className="text-paragraph font-bold text-[15px]">DEVICES</h6>
        <ul>
          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/devices/gateways"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Devices
            </NavLink>
          </li>
        </ul>
        <div>
          <hr className="my-[16px] border-t !border-[#e7eaee]" />
        </div>
        <h6 className="text-paragraph font-bold text-[15px]">FLEET</h6>
        <ul>
          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/dispatch"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Dispatch
            </NavLink>
          </li>

          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/fuel-energy/eco-driving"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Fuel & Energy
            </NavLink>
          </li>

          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/driver-activity/working-hours"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Driver Activity
            </NavLink>
          </li>

          <li className="h-[40px] items-center my-[4px]">
            <NavLink
              to="/settings/addresses/valid-addresses"
              className={({ isActive, isPending }) =>
                `${
                  isPending ? "pending" : isActive ? "bg-inverted" : ""
                } p-[0px_8px] flex items-center whitespace-nowrap h-full hover:bg-inverted transition duration-300 rounded-[8px] `
              }
            >
              Addresses/Geofences
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
