export default function TachnographExplorer() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="flex justify-between items-center">
          <h1 className="text-[26px] text-black font-bold mb-8">
            Tachograph Explorer
          </h1>
        </div>
        <div className="mt-12 max-w-[30%] mx-auto text-center">
          <h2 className="text-[20px] text-heading font-bold">
            Welcome to Tachograph Explorer!
          </h2>
          <p className="text-[18px] font-medium text-[#657281] mt-3">
            Analyze tachograph stats by graphing past and simulating future
            driver activity. Start by selecting a driver from the dropdown below
            or creating one from scratch.
          </p>
          <div className="text-center mt-4 ">
            <select className="bg-white !px-2 !py-2 rounded-md !border !border-[#c1c9d1] mr-4">
              <option>Select a driver</option>
            </select>
            <button className="gap-2 cursor-pointer bg-white !px-2 !py-2 rounded-md !border !border-[#c1c9d1]">
              Create from scratch
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
