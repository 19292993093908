import Filters from "./components/Filters";
import { NavLink } from "react-router-dom";

export default function ComplianceLive() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="flex justify-between items-center">
          <h1 className="text-[26px] text-black font-bold mb-8">
            Drivers’ Hours Report
          </h1>
        </div>
        <div className="border-b border-[#e7eaee] flex items-center">
          <NavLink
            to="/compliance/historical"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Historical
          </NavLink>
          <NavLink
            to="/compliance/live"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Live
          </NavLink>
        </div>
        <Filters />
        <div className="w-full">
          <div className="min-w-[800px]">
            <table className="w-full border-b border-t border-[#e7eaee]">
              <thead className="border-b border-t border-[#e7eaee]">
                <tr>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Driver</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Activity</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Last Vehicle</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Last Location</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Driving</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]"> Shift</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">RDT Today</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-[24px_8px]">EBRAHIM MOOSA</td>
                  <td className="p-[24px_8px]">Rest</td>
                  <td className="p-[24px_8px]">YK22WMJ</td>
                  <td className="p-[24px_8px]">
                    Neville Road, Bradford, England, BD4 8TU
                  </td>
                  <td className="p-[24px_8px]">0m</td>
                  <td className="p-[24px_8px]">0m</td>
                  <td className="p-[24px_8px]">10h</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
