import Gateways from "./components/Gateways";
import SettingsSidebar from "../../../../components/SettingsSidebar";
import { NavLink } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";

export default function Devices() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <div className="flex justify-between items-center">
          <h1 className="text-[26px] text-black font-bold mb-8">Devices</h1>
          <div className="flex items-center gap-3">
            <button className="flex items-center justify-end gap-2 cursor-pointer bg-white !px-2 !py-2 rounded-md !border !border-[#c1c9d1]">
              <AiOutlinePlus size={14} />
              Activate Devices
            </button>
          </div>
        </div>
        <div className="border-b border-[#e7eaee] flex items-center">
          <NavLink
            to="/settings/devices/gateways"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px]  outline-0 border-b-[1px] font-bold`
            }
          >
            Gateways
          </NavLink>
          <NavLink
            to="/settings/devices/cameras"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px]  outline-0 border-b-[1px] font-bold`
            }
          >
            Cameras
          </NavLink>
          <NavLink
            to="/settings/devices/sensors"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px]  outline-0 border-b-[1px] font-bold`
            }
          >
            Sensors
          </NavLink>
        </div>
        <Gateways />
      </div>
    </div>
  );
}
