import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { FaCaretLeft, FaCaretRight, FaGraduationCap } from "react-icons/fa";

export default function AddDriverPopup({ isOpen, closeModal }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[600px] transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <div className="p-6">
                    <h4 className="text-[20px] text-black font-semibold mb-2">
                      Create New Tag
                    </h4>
                    <p className="text-paragraph text-[15px] font-medium">
                      You are now creating a new tag for your organization. Once
                      your tag is created, it can be used to restrict access and
                      filter views across the dashboard.
                    </p>
                  </div>
                  <hr className=" !border-[#e7eaee]" />
                  <div className="bg-[#f2f7ff] p-[16px_24px]">
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => setShow(!show)}
                    >
                      <h5 className="flex items-center gap-2 text-[14px] font-semibold text-[#0a59ad]">
                        <FaGraduationCap size={18} className="text-[#0a59ad]" />
                        How-to Guide
                      </h5>
                      <p className="text-[#0a59ad] text-[14px]">
                        {!show ? "Show tutorial" : "Hide tutorial"}
                      </p>
                    </div>
                    {show && (
                      <div className="mt-4">
                        <h3 className="text-[#0a59ad] text-[18px] mb-2 font-bold">
                          Name the tag and select nesting preference
                        </h3>
                        <p className="text-[#0a59ad] text-[14px] mb-4">
                          When using tags to manage access, utilize nested tags
                          to mimic the hierarchy of your organization. For
                          example, under a wider 'Distribution' tag, you can
                          create tags for each region that falls under
                          Distribution.
                        </p>
                        <div className="flex items-center gap-3">
                          <div>
                            <p className="flex items-center gap-2 text-[#0a59ad]/50 font-semibold">
                              <FaCaretLeft size={14} />
                              Prev
                            </p>
                          </div>
                          <div>
                            <p className="flex items-center gap-2 text-[#0a59ad] font-semibold">
                              Next
                              <FaCaretRight size={14} />
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="px-6 py-4">
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        className="cursor-pointer flex items-center text-[14px] font-medium mr-2"
                        role="none"
                      >
                        <input
                          type="checkbox"
                          name="termOfUse"
                          className="hidden peer"
                          role="none"
                        />
                        <span
                          className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"
                          role="none"
                        ></span>
                        Nest this tag under:
                      </label>
                    </div>
                    <div className="mb-3">
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option></option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Vehicles in Tag
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select Vehicles</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Drivers in Tag
                      </label>
                      <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                        <option>Select drivers...</option>
                      </select>
                    </div>
                  </div>
                  <hr className=" !border-[#e7eaee]" />
                  <div className="px-6 py-4 flex items-center gap-2 justify-end">
                    <button
                      onClick={closeModal}
                      className="!px-4 !py-2 text-[#5a5a5a] !border !border-[#caced3] bg-white rounded-[2px] flex items-center gap-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={closeModal}
                      className="!px-4 !py-2 text-white bg-[#0d6ede] rounded-[2px] flex items-center gap-2"
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
