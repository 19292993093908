import Filters from "./components/Filters";
import { NavLink } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";

export default function Defects() {
  return (
    <div className="flex">
      <div className="p-10 grow overflow-auto h-screen">
        <div className="mb-8 flex justify-between items-start">
          <div className="">
            <h1 className="text-[26px] text-black font-bold mb-1">
              Walkaround Templates
            </h1>
            <p className="text-[#657281] text-[15px] font-medium">
              Assign specific walkaround templates to vehicles or trailers.
            </p>
          </div>
          <button className="flex items-center justify-end gap-2 cursor-pointer bg-white !px-2 !py-2 rounded-md !border !border-[#c1c9d1]">
            <AiOutlinePlus size={14} />
            Create template
          </button>
        </div>
        <div className="border-b border-[#e7eaee] flex items-center">
          <NavLink
            to="/walkaround/templates"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Templates
          </NavLink>
          <NavLink
            to="/walkaround/assignments"
            className={({ isActive, isPending }) =>
              `${
                isPending
                  ? "pending"
                  : isActive
                  ? "!text-heading font-bold border-heading"
                  : "!text-[#4c5661] border-white"
              } p-[16px] text-[15px] outline-0 border-b-[1px] font-bold`
            }
          >
            Assignments
          </NavLink>
        </div>
        <Filters />

        <div className="w-full">
          <div className="min-w-[800px]">
            <table className="w-full border-b border-t border-[#e7eaee]">
              <thead className="border-b border-t border-[#e7eaee]">
                <tr>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">
                      <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                        <input
                          type="checkbox"
                          name="termOfUse"
                          className="hidden peer"
                        />
                        <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                      </label>
                    </span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Asset Name</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Asset Type</span>
                  </th>
                  <th className="p-[32px_8px_16px_8px] whitespace-nowrap">
                    <span className="text-[14px]">Template Name</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-[24px_8px]">
                    <label className="cursor-pointer flex items-center text-[14px] font-medium mr-2">
                      <input
                        type="checkbox"
                        name="termOfUse"
                        className="hidden peer"
                      />
                      <span className="mr-3 peer-checked:bg-primary bg-white h-[16px] w-[16px] before:content-[''] relative before:absolute before:bottom-[5px] before:left-[2px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border border-[#c1c9d1] peer-checked:border-primary rounded-sm"></span>
                    </label>
                  </td>
                  <td className="p-[24px_8px]">GA6D-PMG-UJJ</td>
                  <td className="p-[24px_8px]"> Vehicle</td>
                  <td className="p-[24px_8px]">Heavy Goods Vehicle</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
