import DriversHeader from "./components/DriversHeader";
import { FaMapMarkerAlt } from "react-icons/fa";

export default function Assets() {
  return (
    <div className="p-10">
      <h1 className="text-[26px] text-black font-bold mb-8">Drivers</h1>
      <hr className="border-[#e7eaee]" />
      <DriversHeader />
      <div className="w-full">
        <div className="min-w-[800px]">
          <table className="w-full border-b border-t border-[#e7eaee]">
            <thead className="border-b border-t border-[#e7eaee]">
              <tr>
                <th
                  data-title="name"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Name
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Current Vehicle
                  </span>
                </th>
                <th
                  data-title="vehicleLocation"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span data-isolate="true" className="text-[14px]">
                    Current Location
                  </span>
                </th>
                <th
                  data-title="latestDriverAppVersion"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span className="text-[14px]"> App Version </span>
                </th>
                <th
                  data-title="latestDeviceOSVersion"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span className="text-[14px]">Operating System </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-[24px_8px]">
                  <div>
                    <span className="underline mb-1 inline-block">
                      EBRAHIM MOOSA
                    </span>
                    <div className="text-heading text-[14px]">GANGAT4052</div>
                    <div className="text-heading text-[14px]">000000000000</div>
                  </div>
                </td>
                <td className="p-[24px_8px]">
                  <span class="underline text-heading">YK22WMJ</span>
                </td>
                <td className="p-[24px_8px]">
                  <div className="flex items-center">
                    <div className="shrink-0 mr-2">
                      <FaMapMarkerAlt size={13} className="fill-[#8390a0]" />
                    </div>
                    <span className="text-paragraph text-[14px]">
                      Neville Road, Bradford, England, BD4 8TU
                    </span>
                  </div>
                </td>
                <td className="p-[24px_8px]">-</td>
                <td className="p-[24px_8px]">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
