import { HiOutlineSearch } from "react-icons/hi";

export default function Invoices() {
  return (
    <>
      <div className="flex items-center mt-12">
        <h2 className="text-[18px] text-black font-semibold">Invoices</h2>
        <div className="ml-auto border-none">
          <div className="relative">
            <input
              className="h-[45px] px-8 w-full bg-inverted rounded-md outline-0"
              placeholder="Search invoices"
            />
            <HiOutlineSearch
              size={16}
              className="absolute left-[7px] top-[14px] text-paragraph"
            />
          </div>
        </div>
      </div>
      <hr className="my-3 !border-[#e7eaee]" />
      <h4>Woohoo! No open invoices.</h4>

      <hr className="my-3 !border-[#e7eaee]" />
    </>
  );
}
