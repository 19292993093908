import SettingsSidebar from "../../../../components/SettingsSidebar";
import FuelEnergyTabs from "../components/FuelEnergyTabs";
import { MdEdit } from "react-icons/md";
export default function FuelPurchase() {
  return (
    <div className="flex">
      <SettingsSidebar />
      <div className="p-10 grow overflow-auto h-screen">
        <h1 className="text-[26px] text-black font-bold mb-8">
          Fuel & Energy Settings
        </h1>
        <FuelEnergyTabs />
        <div>
          <div class="border border-[#e7eaee] rounded-[4px] p-[24px] mb-[24px] mt-[24px]">
            <h4 className="text-[20px] font-medium text-heading mb-4">
              Fuel Purchases CSV Uploads
            </h4>
            <h5 className="text-[15px] font-medium text-heading mb-1">
              Set the date format you will upload the transactions in
            </h5>
            <select className="w-full h-[40px] border rounded-md px-3 outline-0">
              <option>Day/Month/Year</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
