import { RxDotFilled } from "react-icons/rx";
import DriverHeader from "../DevicesHeader";

export default function DeactivatedDrivers() {
  return (
    <>
      <DriverHeader />
      <div className="w-full">
        <div className="min-w-[800px]">
          <table className="w-full border-b border-t border-[#e7eaee]">
            <thead className="border-b border-t border-[#e7eaee]">
              <tr>
                <th
                  data-title="name"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                ></th>
                <th
                  data-title="name"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Camera Serial
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Camera
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Linked Gateway
                  </span>
                </th>
                <th
                  data-title="vehicleName"
                  className="p-[32px_8px_16px_8px] whitespace-nowrap"
                >
                  <span
                    data-isolate="true"
                    className="text-[13px] font-bold inline-block"
                  >
                    Linked Asset
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">
                    <RxDotFilled size={32} className="text-[#0db010]" />
                  </div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">G2PE-753-V4A</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true"> CM31</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">G2PE-753-V4A</div>
                </td>
                <td className="p-[24px_8px]">
                  <div data-notranslate="true">YK22WMJ</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
