import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { VscChromeClose } from "react-icons/vsc";

export default function InviteUserPopup({ isOpen, closeModal }) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[600px] transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between p-6">
                    <h4 className="text-[20px] text-black font-semibold">
                      Invite User
                    </h4>
                    <VscChromeClose
                      size={20}
                      onClick={closeModal}
                      className="cursor-pointer"
                    />
                  </div>
                  <hr className=" !border-[#e7eaee]" />

                  <div className="px-6 py-4">
                    <div className="mb-3">
                      <label className="text-[14px] text-black mb-1 font-semibold">
                        Email <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        className="w-full h-[40px] border rounded-md px-3 outline-0"
                      />
                    </div>
                    <div className="flex items-center gap-2 mb-3">
                      <div className="flex-1">
                        <label className="text-[14px] text-black mb-2 font-semibold">
                          Role
                        </label>
                        <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                          <option>Full Admin</option>
                        </select>
                      </div>
                      <div className="flex-1">
                        <label className="text-[14px] text-black mb-2 font-semibold">
                          Access
                        </label>
                        <select className="w-full h-[40px] border rounded-md px-3 outline-0">
                          <option>Entire Organization</option>
                        </select>
                      </div>
                    </div>
                    <h4 className="text-[14px] text-black font-semibold mt-6 mb-3">
                      Permissions
                    </h4>
                    <div className="flex items-center gap-20">
                      <h6 className="text-[14px] text-black font-semibold">
                        Full Admin
                      </h6>
                      <p className="text-paragraph text-[14px]">
                        {" "}
                        Full edit access to all dashboard pages.
                      </p>
                    </div>
                  </div>
                  <hr className=" !border-[#e7eaee]" />
                  <div className="px-6 py-4 flex items-center gap-2 justify-end">
                    <button
                      onClick={closeModal}
                      className="!px-4 !py-2 text-[#5a5a5a] !border !border-[#caced3] bg-white rounded-[2px] flex items-center gap-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={closeModal}
                      className="!px-4 !py-2 text-white bg-[#0d6ede] rounded-[2px] flex items-center gap-2"
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
